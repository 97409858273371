var contactRegions, contactRbhRegions;
var contactForms = {
	contactGeneralEnquiry: ['howDidYouHear'],
	contactLivingHenley: ['requestType', 'address'],
	contactBuildingHenley: ['jobNumber'],
	lookingToBuildBuy: ['enquiry', 'region', 'howDidYouHear', 'subscribe']
}
var googleMapsLoaded = false;

// gleaned from https://stackoverflow.com/a/7557433, with changes
function isElementInViewport(el) {
	if (typeof jQuery === 'function' && el instanceof jQuery) {
		el = el[0];
	}
	var rect = el.getBoundingClientRect();
	/*
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) || // or $(window).height()
		rect.right <= (window.innerWidth || document.documentElement.clientWidth) // or $(window).width()
	)
	*/
	return (
		Math.floor(rect.top) <= window.innerHeight &&
		Math.floor(rect.right) <= window.innerWidth
	)
}

$(document).ready(function() {
	// gtm
	function gtmTrigger(data) {
		dataLayer.push(data);
	}

	// bootstrap tooltip activate
	$('[data-toggle="tooltip"]').tooltip();

	$('.open-popup-link').magnificPopup({
		type:'inline',
		midClick: true
	});

	$('.video-popup-link').magnificPopup({
      type: 'iframe',
      iframe: {
       markup: '<div class="mfp-iframe-scaler">' +
                  '<div class="mfp-close"></div>' +
                  '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                  '</div>' +
                  '<div class="mfp-bottom-bar">' +
                  '<div class="mfp-title"></div>' +
                  '</div>',
          patterns: {
              youtube: {
                  index: 'youtube.com/',
                  id: function(url) {
                      var m = url.match(/[\\?\\&]v=([^\\?\\&]+)/);
                      if ( !m || !m[1] ) return null;
                      return m[1];
                  },
                  src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
              },
              vimeo: {
                  index: 'vimeo.com/',
                  id: function(url) {
                      var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
                      if ( !m || !m[5] ) return null;
                      return m[5];
                  },
                  src: '//player.vimeo.com/video/%id%?autoplay=1'
              }
          }
      }
  });

	// intro popup handler
	$('.hero_block__button:not(.page_link)').on('click', function() {
		var thisID = $(this).attr('id'),
			buttonClickID = thisID.replace('intro-popup', 'hero-popup');

		function openIntroPopup(buttonClickID) {
			$('#' + buttonClickID).click();
			$('html').addClass('no-scroll');
			$('body').addClass('modal-open');
			$('.intro-popup').children('.intro_bottom').fadeOut();
			$('.intro-popup').children('.hero_block').fadeOut();
			$('.hero-popup-shell').fadeIn(function() {
				$(this).addClass('on');
			});
		}

		if ($(window).scrollTop() > 0) {
			$('html, body').animate({
				scrollTop: 0
			}, 600, function() {
				openIntroPopup(buttonClickID);
			});
		}
		else {
			openIntroPopup(buttonClickID);
		}
		return false;
	});
	$('.hero-popup__inside').on('click', 'a.close-popup', function() {
		$('.hero-popup-shell').fadeOut(function() {
			$(this).removeClass('on');
			$('html').removeClass('no-scroll');
			$('body').removeClass('modal-open');
		});
		$('.intro-popup').children('.intro_bottom').fadeIn();
		$('.intro-popup').children('.hero_block').fadeIn();
		return false;
	});

	// intro section navigation arrow - on click scroll to recently viewed section
	$('.intro_bottom__arrow-icon').click(function(){
		var offsetTop;
		if ($('#recently-viewed-section').length > 0) offsetTop = $('#recently-viewed-section').offset().top + 5;
		else if ($('#what-we-value-section').length > 0) offsetTop = $('#what-we-value-section').offset().top + 5;
		else offsetTop = $('#intro-section').next('section').offset().top + 5;

		if (typeof offsetTop != 'undefined') {
			$('html, body').animate({
				scrollTop: offsetTop
			}, 600);
		}
	});

	function introSearchForm() {
		if ($('.intro-search-form').length > 0) {
			var $introSearchForm = $('.intro-search-form'),
				$introSectionSlider = $('.intro-section--slider'),
				$navBar = $('.header'),
				introSearchFormHeight = $introSearchForm.outerHeight(),
				introSectionSliderHeight = $introSectionSlider.outerHeight();

			if (
				window_width() <= 767 ||
				(window.matchMedia("(orientation: landscape)").matches && window_width() <= 1024) ||
				window_height() <= 850
				) {
				var newHeight = $(window).height() - $navBar.outerHeight();
			}
			else
				var newHeight = $(window).height() - $navBar.outerHeight() - introSearchFormHeight;

			$('#intro-section').css('height', newHeight + 'px');
			$('.intro-section--slider').css('height', newHeight + 'px');
			$('.intro-section--slider .slick-slide').css('height', newHeight + 'px');
		}
	}

	$('.icon-anchor-link').click(function(e){
		e.preventDefault();
		var datalink = $(this).attr('data-link');
		var offsetTop = $(datalink).offset().top - 100;
		$('html, body').animate({
				scrollTop: offsetTop
			}, 600);
	});

	$('.icon-anchor-link-360').click(function(e){
		e.preventDefault();
		var offsetTop = $('#virtual-tours').offset().top - 100;
		$('html, body').animate({
				scrollTop: offsetTop
			}, 600);
	});

	$('.icon-anchor-link-video').click(function(e){
		e.preventDefault();
		$("#gallerySection .nav-secondary a[data-class='video']").trigger('click');
		var offsetTop = $('#gallerySection').offset().top - 100;
		$('html, body').animate({
			scrollTop: offsetTop
		}, 600);
	});
	$('.icon-anchor-link-image-gallery').click(function(e) {
		e.preventDefault();
		//console.log('george');
		$("#gallerySection .nav-secondary a[data-class='interior']").trigger('click');
		var offsetTop = $('#gallerySection').offset().top - 100;
		$('html, body').animate({
			scrollTop: offsetTop
		}, 600);
	});
	$('.icon-anchor-link-image-facade').click(function(e) {
		e.preventDefault();
		//console.log('george');
		$("#gallerySection .nav-secondary a[data-class='facade']").trigger('click');
		var offsetTop = $('#gallerySection').offset().top - 100;
		$('html, body').animate({
			scrollTop: offsetTop
		}, 600);
	});

	// footer back-to-top button scroll top on click
	$('.back-to-top').click(function(){
		$('html, body').animate({
			scrollTop: 0
		}, 600);
	});

	$('button.footer-top-section__button').on('click', function() {
		var $thisButton = $(this);
			if ($thisButton.attr('data-url') != 'undefined') {
				if ($thisButton.length > 0) {
					window.location = $thisButton.attr('data-url');
				}
			}
			return false;
	});

	$('a.contact-box').on('click', function() {
		if ($(this).attr('href') == '') {
			var headingHTML = $(this).children('.heading').html();
			$('#contactForm').find('.form-enquiry-secondary__aside').children('h3').html(headingHTML);

			if (typeof $(this).attr('data-ajax-call') != 'undefined') {
				$('#contactForm').attr('data-ajax-call', $(this).attr('data-ajax-call'));
			}
			if (typeof $(this).attr('data-form-type') != 'undefined') {
				var formType = $(this).attr('data-form-type'),
					formPageID = $(this).attr('data-form-page-id');
				$('#contactForm').find('#send_to').val(formType);
				if (typeof formPageID != 'undefined' && formPageID !== false) {
					$('#contactForm').find('#form_page_id').val(formPageID);
				}
			}

			// sort out which fields to expose to the form
			$('#contactForm').find('.toggleable').hide();
			if (typeof $(this).attr('data-form-type') != 'undefined') {
				var formType = $(this).attr('data-form-type');
				if (typeof contactForms[formType] != 'undefined') {
					var exposeClasses = contactForms[formType];
					if (exposeClasses.length > 0) {
						for (i in exposeClasses) {
							exposeThisClass = exposeClasses[i];
							$('.toggleable-' + exposeThisClass).show();
						}
					}
				}
			}
			$('#modal-contact-form').modal();
			return false;
		}
	});


	$('#modal-enquiry-form').on('change', 'select', function() {
		if($(this).attr('id') != 'field-region') {
			$(this).css('color','#231f20');
		}
	});

	$('#modal-enquiry-form').on('change', '#field-region', function() {
		if($(this).find('option:selected').text() != '') {
			$(this).css('color','#231f20');
		}
	});

	$("#contactForm").on('change', 'select', function() {
		if ($(this).find('option:selected').attr('value') != '') $(this).css('color', '#231f20');
		else $(this).css('color', '');

		if ($(this).attr('id') == 'field-region' && $('#field-sales-centre').length > 0) {
			if ($('#field-sales-centre').val() != '') $('#field-sales-centre').css('color', '#231f20');
			else $('#field-sales-centre').css('color', '');
		}
	});

	$(".nature-enquiry-select").change(function() {
   		 $('.describes_select').hide();
   		 if($(".nature-enquiry-select option:selected").text() == "Completed Homes For Sale"){
	   		 $('#readybuilt_select').show();
   		 } else {
	   		 $('#non_readybuilt_select').show();
   		 }

   		if($(".nature-enquiry-select option:selected").text() == "Dual Occupancy"){
   		 	$('#contactForm .form-enquiry-secondary__aside, #contactForm .form-enquiry-secondary__content').hide();
			$('#contactForm .dual_occupancy_warning').removeClass('inactive');

   		}

	});
	
	$("#trade").change(function(e) {
   		if($("#trade option:selected").text() == "Other – (Free Text)"){
			$('.trade_text_container').removeClass('hidden');
   		} else {
	   		$('.trade_text_container').addClass('hidden');
   		}
	});
	

	$(".booking_nature_of_enquiry").change(function(e) {
   		if($(".booking_nature_of_enquiry option:selected").text() == "Dual Occupancy"){
			$('.dual_occupancy_warning').removeClass('hidden');
   		}
	});

	$(".booking_nature_of_enquiry_appt").change(function(e) {
   		if($(".booking_nature_of_enquiry_appt option:selected").text() == "Dual Occupancy"){
			$('.dual_occupancy_warning_appt').removeClass('hidden');
   		}
	});

	$(".dual_dimiss").click(function() {
			$('#contactForm .form-enquiry-secondary__aside, #contactForm .form-enquiry-secondary__content').show();
			$('#contactForm .dual_occupancy_warning').addClass('inactive');
			$(".nature-enquiry-select").find('option:eq(0)').prop('selected', true);

	});
	$(".book_dual_dimiss").click(function(e) {
			e.preventDefault();
			$('.dual_occupancy_warning').addClass('hidden');
			$(".booking_nature_of_enquiry").find('option:eq(0)').prop('selected', true);

	});

	$(".book_dual_dimiss_appt").click(function(e) {
			e.preventDefault();
			$('.dual_occupancy_warning_appt').addClass('hidden');
			$(".booking_nature_of_enquiry_appt").find('option:eq(0)').prop('selected', true);

	});

	if($('body').hasClass('estate')){
		salespersonPhone = $('.estate_contact').attr('data-salesperson-phone');
		if (typeof salespersonPhone != 'undefined') {
			if (salespersonPhone.length > 0) {
				$('.estate_contact').attr('href', 'tel:+61' + salespersonPhone.replace(/\s|\-|\.|\(|\)/g, '').replace(/^0/, ''));
			}
		}
	}

	if($('body').hasClass('display-centre-single')){
		salespersonPhone = $('.estate_contact').attr('data-salesperson-phone');
		$('.estate_contact').attr('href', 'tel:+61' + salespersonPhone.replace(/\s|\-|\.|\(|\)/g, '').replace(/^0/, ''));
	}

	// bootstrap modal interaction improvements
	$('body').on('click', '.modal-backdrop', function() {
		// dismiss modal on backdrop click
		var $thisModal = $('.modal.in');
		if (typeof $thisModal != 'undefined') {
			if ($thisModal.is(':visible')) {
				$thisModal.find('.modal-close').trigger('click');
			}
		}
		return false;
	}).on('keyup', function(event) {
		if (event.keyCode == 27) {
			// dismiss modal on esc key press
			var $thisModal = $('.modal.in');
			if (typeof $thisModal != 'undefined') {
				if ($thisModal.is(':visible')) {
					$thisModal.find('.modal-close').trigger('click');
				}
			}
			return false;
		}
	});

	$('.ctaForm').on('change', 'select', function() {
		$(this).css('color','#231f20');
	});

	$('.js-sales-centre-dropdown').on('change', '.sales-centre-select', function () {
		var $hiddenSalesCentreName = $('.js-sales-centre-dropdown').find('input[name=sales_centre_name]');
		if ($hiddenSalesCentreName.length > 0) {
			if ($(this).val() != '') {
				var salesCentreName = $(this).find('option:selected').html();
				$hiddenSalesCentreName.val(salesCentreName);
			}
			else {
				$hiddenSalesCentreName.val('');
			}
		}
	});

	// populate any region / sales centre dropdowns
	if ($('.field-region').length > 0) {
		$.ajax({
			type: 'POST',
			url: '/ajax/contact_get_dropdowns.php',
			data: ({ action: 'regions' }),
			dataType: 'json',
			success: function(response) {
				if (response.status == 'ok') {
					// if (response.result.length > 0) {
					if (Object.keys(response.result).length > 0) {
						// reset the region and sales centre dropdowns
						$('.field-region').each(function() {
							$(this).find('option').text('Select your region *').prop('selected', true);

						});
						$('.field-sales-centre').each(function() {
							$(this).find('option').remove().end().append('<option value="" selected>Select your display centre</option>');
							$("#field-region").val($("#target option:first").val());
						});

						if ($('body').hasClass('readybuilt') || $('body').hasClass('readybuilt-landing')) {
							for (i in response.result.rbh) { // rbh regions
								thisRegion = response.result.rbh[i];
								$(".field-region").each(function() {
									$(this).append('<option value="' + thisRegion.name + '">' + thisRegion.name + '</option>');
								});
							}
						}
						else {
							for (i in response.result.henley) { // henley regions
								thisRegion = response.result.henley[i];
								$(".field-region").each(function() {
									$(this).append('<option value="' + thisRegion.name + '">' + thisRegion.name + '</option>');
								});
							}
						}
						contactRegions = response.result.henley;
						contactRbhRegions = response.result.rbh;

						if ($('body').hasClass('house-and-land')) {
							$('.field-region').each(function() {
								if (typeof $(this).attr('data-region') != 'undefined') {
									var selectedRegion = $(this).attr('data-region');
									$(this).val(selectedRegion);
									$(this).trigger('change');
								}
							});
						}
					}

					$('#contactForm').on('change', '#field-enquiry', function() {
						var theseRegions;
						if ($(this).val() == 'Completed Homes For Sale') theseRegions = contactRbhRegions;
						else theseRegions = contactRegions;

						$('.field-region').each(function() {
							$(this).find('option').remove().end().append('<option value="" selected>Select your region</option>');
							for (i in theseRegions) {
								var currentRegion = theseRegions[i];
								$(this).append('<option value="' + currentRegion.name + '">' + currentRegion.name + '</option>');
							}
						});
					});
					$("#field-region").val($("#target option:first").val());
				}
			}
		});
	}
	$('.field-region').on('change', function() {
		var thisRegion = $(this).val(),
			enquiringAbout = $('#field-enquiry').val(),
			$salesCentre = $('.field-sales-centre');

		if (enquiringAbout == 'Completed Homes For Sale' || $('body').hasClass('readybuilt') || $('body').hasClass('readybuilt-landing')) {
			$salesCentre.each(function() {
				$(this).find('option').remove().end().append('<option value="" selected>Select your display centre</option>');
				for (i in contactRbhRegions) {
					var currentRegion = contactRbhRegions[i];
					if (currentRegion.name == thisRegion) {
						$salesCentre.find('option').remove().end();
						for (j in currentRegion.salesCentres) {
							currentSalesCentre = currentRegion.salesCentres[j];
							$salesCentre.append('<option value="' + currentSalesCentre + '">' + currentSalesCentre + '</option>');
						}
					}
				}
			});
		}
		else {
			$salesCentre.each(function() {
				$(this).find('option').remove().end().append('<option value="" selected>Select your display centre</option>');

				for (i in contactRegions) {
					var currentRegion = contactRegions[i];
					if (currentRegion.name == thisRegion) {
						//$salesCentre.find('option').remove().end();
						for (j in currentRegion.salesCentres) {
							currentSalesCentre = currentRegion.salesCentres[j];
							$salesCentre.append('<option value="' + currentSalesCentre + '">' + currentSalesCentre + '</option>');
						}
					}
				}
			});
		}
	});
		// populate the contact form fields
	if ($('#contactForm').length > 0) {
		$('#contactForm').on('change', 'input[name=request_type]', function() {
			var requestType = $(this).val();
			$('.toggleable-general-maintenance-blurb, .toggleable-urgent-maintenance-blurb, .toggleable-general-urgent-maintenance').hide();
			$('.toggleable-image-upload').hide();

			if (requestType == 'Maintenance') {
				$('.toggleable-maintenance').show();
				$('.toggleable-image-upload').show();

				$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
					$(this).prop('disabled', true);
				});

				if ($('input[name=maintenance_request]:checked').val() == "A general maintenance issue") {
					$('.toggleable-general-maintenance-blurb, .toggleable-general-urgent-maintenance').show();
					$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
						$(this).prop('disabled', false);
					});
				}
				if ($('input[name=maintenance_request]:checked').val() == "Requiring urgent attention") {
					$('.toggleable-urgent-maintenance-blurb, .toggleable-general-urgent-maintenance').show();
					$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
						$(this).prop('disabled', false);
					});
				}
			}
			else {
				$('.toggleable-maintenance').hide();
				$('.toggleable-image-upload').hide();

				$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
					$(this).prop('disabled', false);
				});
			}
		});
		$('#contactForm').on('change', 'input[name=maintenance_request]', function() {
			var maintenanceType = $(this).val();
			if (maintenanceType == 'A general maintenance issue') {
				$('.toggleable-general-maintenance-blurb').show();
				$('.toggleable-urgent-maintenance-blurb').hide();
				$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
					$(this).prop('disabled', false);
				});
				$('.toggleable-general-urgent-maintenance, .toggleable-image-upload').show();
			}
			else if (maintenanceType == 'Requiring urgent attention') {
				$('.toggleable-general-maintenance-blurb').hide();
				$('.toggleable-urgent-maintenance-blurb').show();
				$('.toggleDisable').find('input, select, textarea, .checkbox label span').each(function() {
					$(this).prop('disabled', false);
				});
				$('.toggleable-general-urgent-maintenance, .toggleable-image-upload').show();
			}
		});

		$('#contactForm').on('change', '.form-enquiry-secondary__input-file', function(e) {
			var $input = $(this),
				label = $input.next(),
				fileName = '',
				$filesLabel = $input.siblings('.form-enquiry-secondary__input-file-label');
			if ($input[0].files && $input[0].files.length > 1) fileName = ($input.attr('data-multiple-caption') || '').replace('{count}', $input[0].files.length);
			else fileName = e.target.value.split('\\').pop();

			if (fileName) {
				$filesLabel.html(fileName);
			}
			else {
				$filesLabel.html('');
			}
		});

		$('#contactForm').validate({
			errorClass: 'error',
			highlight: function(element, errorClass) {
				$(element).parents('.form-enquiry-secondary__field-container').addClass('error');
			},
			unhighlight: function(element, errorClass) {
				$(element).parents('.form-enquiry-secondary__field-container').removeClass('error');
			},
			errorPlacement: function(error, element) {

			},
			submitHandler: function(form) {
				var ajaxURL = '/ajax/' + $('#contactForm').attr('data-ajax-call') + '.php',
					$submitButton = $('#contactForm .form-enquiry-secondary__actions input[type=submit]'),
					submitValue = $submitButton.val();
				$submitButton.prop('disabled', true).val('Submitting...');
				fadeLoader('in');
				$('#contactForm').ajaxSubmit({
					type: 'POST',
					url: ajaxURL,
					dataType: 'json',
					traditional: true,
					filtering: function(el, index) {
						if($(el).parent().is(':visible')) {
							return el;
						}
						if($(el).attr('id') == 'send_to') {
							return el;
						}
					},
					success: function(response) {
						fadeLoader('out');
						$submitButton.prop('disabled', false).val(submitValue);
						if (response.status == 'ok') {

							$('#modal-contact-form').addClass('small-modal');
							$('#contactForm .form-enquiry-secondary__aside, #contactForm .form-enquiry-secondary__content').hide();
							if (response.message.length > 0) {
								$('#contactForm .form-enquiry-secondary__success h2').html(response.message);
							}
							$('#contactForm .form-enquiry-secondary__success').not('.dual_occupancy_warning').removeClass('inactive');

							// push to GTM
							dataLayer.push({
								formType: $('#send_to').val(),
								formId: '#contactForm',
								event: 'formSubmissionSuccess'
							});
						}
						else {
							alert(response.message);
						}
					}
				});
			}
		});
		$('#modal-contact-form').on('hidden.bs.modal', function() {
			// reset the form
			$('#contactForm')[0].reset();
			// clear out any errors
			$('#contactForm').find('.error').removeClass('error');
			$('#contactForm .form-enquiry-secondary__aside, #contactForm .form-enquiry-secondary__content').show();
			$('#contactForm .form-enquiry-secondary__success').addClass('inactive');
			$('#contactForm #upload-file').prop('disabled', true);
		});
	}

	// enquiry form
	$('.widget-enquiry__btn, .call_to_action__enquiry').on('click', function() {
		if ($(this).hasClass('widget-enquiry__btn') && $('body').hasClass('readybuilt')) {
			if ($('body').hasClass('readybuilt_lot')) {
				$('#modal-enquiry-form').modal();
				return false;
			}
			else if ($('body').hasClass('readybuilt_townhomes')) {
				var addressOfEnquiry = $(this).attr('data-address'),
					pageID = $(this).attr('data-page-id'),
					salesCentreID = $(this).attr('data-sales-id');
				$('#modal-enquiry-form').find('.form-enquiry-secondary__aside em').html(addressOfEnquiry);
				$('#modal-enquiry-form').find('#page_id').val(pageID);
				$('#modal-enquiry-form').find('#salesCentreID').val(salesCentreID);
				$('#modal-enquiry-form').modal();
				return false;
			}
		}
		else if ($(this).parents('.container__widget').length) {
			$('#address_of_enquiry').val('');
			$('#modal-enquiry-form-widget').modal();
		}
		else {
			$('#address_of_enquiry').val('');
			$('#modal-enquiry-form').modal();
			return false;
		}
	});

	$('#enquiryForm').validate({
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).parents('.form-enquiry-secondary__field-container').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).parents('.form-enquiry-secondary__field-container').removeClass('error');
		},
		errorPlacement: function(error, element) {

		},
		submitHandler: function(form) {
			var serializedForm = $(form).serialize(),
				ajaxURL = '/ajax/' + $('#enquiryForm').attr('data-ajax-call') + '.php',
				$submitButton = $('#enquiryForm .form-enquiry-secondary__actions input[type=submit]'),
				submitValue = $submitButton.val();
			$submitButton.prop('disabled', true).val('Submitting...');
			$.ajax({
				type: 'POST',
				url: ajaxURL,
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).val(submitValue);
					if (response.status == 'ok') {
						dataLayer.push({
							formType: $('#send_to').val(),
							formId: 'enquiryForm',
							enquiringAbout: $('#name_of_series').val(),
							event: 'formSubmissionSuccess'
						});

						$('#modal-enquiry-form').addClass('small-modal');
						$('#enquiryForm .form-enquiry-secondary__content').hide();
						$('#enquiryForm .form-enquiry-secondary__aside').attr('style', 'display: none !important');
						$('#enquiryForm .form-enquiry-secondary__success').not('.dual_occupancy_warning').removeClass('inactive');
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});
	$('#modal-enquiry-form').on('hidden.bs.modal', function() {
		// reset the form
		$('#enquiryForm')[0].reset();
		var $regionFields;
		$regionFields = $('#enquiryForm').find('#field-region');
		if ($regionFields.length > 0) {
			$regionFields.each(function() {
				var selectedRegion = $(this).attr('data-region');
				$(this).val(selectedRegion);
				//$(this).trigger('change');
			});
		}
		// clear out any errors
		$('#enquiryForm').find('.error').removeClass('error');
		$('#enquiryForm .form-enquiry-secondary__aside, #enquiryForm .form-enquiry-secondary__content').show();
		$('#enquiryForm .form-enquiry-secondary__success').addClass('inactive');
	});

	// sitemap button toggle on click
	/*
	$('.sitemap-toggle-btn').click(function(){
		$('.sitemap-wrapper').slideToggle();
		$(this).toggleClass('active');
		return false;
	});
	*/

	// heart button animate on click
	$('.action-buttons__favorite').click(function(){
		$(this).addClass('pulse');
		setTimeout(function () {
			$('.action-buttons__favorite').removeClass('pulse');
		}, 500);
	});

	// compare button animate on click
	$('.main-part').on('click', '.action-buttons__compare', function() {
		$(this).addClass('pulse');
		setTimeout(function () {
			$('.action-buttons__compare').removeClass('pulse');
		}, 500);

		var pageID = $(this).parents('.property').attr('data-id'),
			type   = $(this).attr('data-type');
		$.ajax({
			type: 'POST',
			url: '/ajax/add_to_compare.php',
			data: ({ pageID: pageID, type: type }),
			dataType: 'json',
			success: function(response) {
				if (response.status == 'ok') {
					$('.compare-tooltip').show();
					$('.fixed-compare').children('.compare-count').html(response.sessionCount);
					$('.fixed-compare').fadeIn();
				}
				else {
					alert(response.message);
				}
			}
		});
		return false;
	});


	$('.li-has-submenu:not(.li-keep-menu-open)').hover(
	  function() {
	    $(this).find('.submenu').stop().slideDown(300);
	  }, function() {
	    $(this).find('.submenu').stop().slideUp(300);
	  }
	);

	// mouse enters mobile nav + links
	$('.expandable-mobile').click(function(e){
		e.preventDefault();

		$(this).toggleClass('active');
		if($(e.target).is('a') && !$(this).hasClass('active')){
			window.location.href = $(e.target).attr('href');
		}
		if ($(this).find('.menu-expand-btn').text() == "+") {
			$(this).find('.menu-expand-btn').text('-');
		} else {
			$(this).find('.menu-expand-btn').text('+');
		}
		return false;
	});

	var scrollTo;
	// mobile sidenav open/close
	$('.header__mobile-button').click(function(){
		$('html').toggleClass('menu-active');
		$(this).toggleClass('active');

		// stops page scrolling, there is a slight jump when opened to top of page
		// which we reset once closed.
		if($('html').hasClass('menu-active')){
			scrollTo = $('body').scrollTop();
		}else{
			$('body').animate({scrollTop: scrollTo}, 0);
		}
	});

	var resizeTimeout;
	$(window).resize(function() {
		//mq = window.matchMedia( "(max-width: 992px)" );
		/* If window >992px close sidenav */

		if ($('body').hasClass('book-call-back-landing-page')) {
			var $header = $('header');
			if ($(window).width() < 992) {
				var headerHeight = $header.css('height');
				$header.css('height', 'auto').attr('data-height', headerHeight);
			}
			else {
				$header.css('height', 'auto').attr('data-height', null);
			}
		}

		if(window_width() <= 767) $('.features .feature').addClass('no_hover');
		else $('.features .feature').removeClass('no_hover');

		threeColumnsSlider();
	});

	function threeColumnsSlider() {
		if ($('.three-columns').length > 0) {
			if (window_width() <= 767) {
				if (!$('.three-columns').hasClass('slick-initialized')) {
					$('.three-columns').slick({
						slidesToShow: 1,
						slidesToScroll: 1,
						autoplay: false,
						arrows: true,
						dots: true
					});
				}
			}
			else {
				if ($('.three-columns').hasClass('slick-initialized')) {
					$('.three-columns').slick('unslick');
				}
			}
		}
		return true;
	}
	threeColumnsSlider();

	if($(".intro-section--slider").length){
		$('.intro-section--slider').on('init', function (event, slick) {
			// render the first image
			var $firstSlide = $('.intro-section--slider .slick-track .slick-slide.slick-current');
			if (typeof $firstSlide.data('geo-bg-srcset') != 'undefined') {
				if ($firstSlide.data('geo-bg-srcset').length > 0) {
					$firstSlide.css('background-image', 'url(' + $firstSlide.data('geo-bg-srcset') + ')');
					$firstSlide.removeData('geo-bg-srcset');
				}
			}
			// process the cloned slides now
			var clonedSlidesBgSrcsets = setTimeout(function() {
				$('.intro-section--slider .slick-track .slick-slide.slick-cloned').each(function (index, thisClonedSlide) {
					$thisClonedSlide = $(thisClonedSlide);
					if (typeof $thisClonedSlide.data('geo-bg-srcset') != 'undefined') {
						if ($thisClonedSlide.data('geo-bg-srcset').length > 0) {
							$thisClonedSlide.css('background-image', 'url(' + $thisClonedSlide.data('geo-bg-srcset') + ')');
							$thisClonedSlide.removeData('geo-bg-srcset');
						}
					}
				});
			}, 5000);
		});
		$('.intro-section--slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			var $thisSlick = $('.intro-section--slider').slick('getSlick');
			var $nextSlide = $($thisSlick.$slides[nextSlide]);
			if (typeof $nextSlide.data('geo-bg-srcset') != 'undefined') {
				if ($nextSlide.data('geo-bg-srcset').length > 0) {
					$nextSlide.css('background-image', 'url(' + $nextSlide.data('geo-bg-srcset') + ')');
					$nextSlide.removeData('geo-bg-srcset');
				}
			}
		});
		$(".intro-section--slider").slick({
			dots: true,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			focusOnSelect:true,
			swipeToSlide: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 15000,
			speed: 600,
			/*
			autoplay: false,
			autoplaySpeed: 15000,
			pauseOnHover: false
			*/
		});
	}

	function addParameter(url, parameterName, parameterValue, atStart/*Add param before others*/){
		replaceDuplicates = true;
		if(url.indexOf('#') > 0){
			var cl = url.indexOf('#');
			urlhash = url.substring(url.indexOf('#'),url.length);
		} else {
			urlhash = '';
			cl = url.length;
		}
		sourceUrl = url.substring(0,cl);

		var urlParts = sourceUrl.split("?");
		var newQueryString = "";

		if (urlParts.length > 1)
		{
			var parameters = urlParts[1].split("&");
			for (var i=0; (i < parameters.length); i++)
			{
				var parameterParts = parameters[i].split("=");
				if (!(replaceDuplicates && parameterParts[0] == parameterName))
				{
					if (newQueryString == "")
						newQueryString = "?";
					else
						newQueryString += "&";
					newQueryString += parameterParts[0] + "=" + (parameterParts[1]?parameterParts[1]:'');
				}
			}
		}
		if (newQueryString == "")
			newQueryString = "?";

		if(atStart){
			newQueryString = '?'+ parameterName + "=" + parameterValue + (newQueryString.length>1?'&'+newQueryString.substring(1):'');
		} else {
			if (newQueryString !== "" && newQueryString != '?')
				newQueryString += "&";
			newQueryString += parameterName + "=" + (parameterValue?parameterValue:'');
		}
		return urlParts[0] + newQueryString + urlhash;
	};

	// home page search above fold event handling
	$('.build-house__go').on('click touchstart', function() {
		// new home / house and land
		var linkTo = $(this).attr('href'),
			selectedStoreys     = $('.select-storey:checked').val(),
			selectedLotWidth    = $('#lot-width').val(),
			selectedLotDepth    = $('#lot-depth').val();

		if (selectedStoreys.length > 0) linkTo = addParameter(linkTo, 'storeys', selectedStoreys);
		if (selectedLotWidth.length > 0) linkTo = addParameter(linkTo, 'lot_width', selectedLotWidth);
		if (selectedLotDepth.length > 0) linkTo = addParameter(linkTo, 'lot_depth', selectedLotDepth);

		window.location = linkTo;
		return false;
	});

	if($(".testimonials--slider").length){
		$(".testimonials--slider__slides").slick({
			dots: true,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			focusOnSelect:true,
			swipeToSlide: true,
			arrows: false,
			//autoplay: true,
			//autoplaySpeed: 6000,
			speed: 600,
		});

		$('.testimonials--slider__slides').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			var $thisSlide = $(slick.$slides.get(currentSlide)),
				iframe = $thisSlide.find('iframe');

			if(iframe.length){
				var vimeoPlayer = new Vimeo.Player(iframe);
				vimeoPlayer.unload();
				iframe.fadeOut()
			}
		})

		$(window).on('load resize', function(){
			eqHeight($('.testimonials--slider__slide .js-eqHeight'))
		});
	}

	$('.testimonials, .testimonial').on('click','.testimonials-play-button', function(){
		var	$this = $(this);

		if($this.hasClass('open-modal')){
			var modal = $('#modal-testimonial-video'),
				iframe = modal.find('iframe');
			modal.modal('show');

			//autoplay video
			iframe.attr('src',$this.data('vimeo') );
			var vimeoPlayer = new Vimeo.Player(iframe);
			vimeoPlayer.play();

		}else{
			var	iframe = $this.parent().find('iframe'),
				vimeoPlayer = new Vimeo.Player(iframe);
			vimeoPlayer.play();
			iframe.fadeIn()
		}
	})
	$('.book-call-back-google-reviews .slick-container').slick({
		dots: true,
		arrows: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		swipeToSlide: true
	});
	$('#modal-testimonial-video').on('hidden.bs.modal', function () {
	  	var iframe = $(this).find('iframe');
		if(iframe.length){
			var vimeoPlayer = new Vimeo.Player(iframe);
			vimeoPlayer.unload();
		}
	})

	if($('.testimonials--grid-item').length){
		var sm_width = 767,
			md_width = 992;
		function testimonialsEqHeight(){
			var window_width = $(window).width(),
				dataRowSelector = "";

			if(window_width > md_width){
				dataRowSelector = "md"
			}else if(window_width > sm_width){
				dataRowSelector = "sm"
			}

			if(dataRowSelector == ""){
				//mobile
				$('.testimonials--grid-item').css('height','auto');
			}else{
				var rowsArray = [];
				$('.testimonials--grid-item').each(function(){
					//add element to row;
					var rowNumber = $(this).attr('data-' + dataRowSelector + '-row');
					if($.inArray( rowNumber, rowsArray ) == -1){
						rowsArray.push(rowNumber);
					}
				})
				$.each(rowsArray, function(){
					eqHeight($('.testimonials--grid-item[data-' + dataRowSelector + '-row=' + this + ']'))
				})
			}
		}
		testimonialsEqHeight()
		$(window).on('load resize', function(){
			testimonialsEqHeight()
		});

		$('.testimonials--grid-item-see-more').click(function(e){
			if(!mobileView()){
				e.preventDefault();
				var $this = $(this),
					window_width = $(window).width(),
					dataRowSelector = "",
					$readMoreClone = $('.testimonials--grid-more.clone').clone();

				//remove old one
				$('.testimonials--grid-more:not(.clone)').slideUp(300,function(){
					$(this).remove();
				});

				//get details for read more div
				var $seeMoreDataDiv = $this.find('.js-see-more-data');

				$readMoreClone.find('.name').text($seeMoreDataDiv.data('name'));
				//$readMoreClone.find('.short_quote').text($seeMoreDataDiv.data('short_quote'));
				$readMoreClone.find('.description').html($seeMoreDataDiv.data('description'));
				$readMoreClone.find('.series').text($seeMoreDataDiv.data('series'));
				$readMoreClone.find('.image').css('background-image','url(' + $seeMoreDataDiv.data('image') + ')');

				if($seeMoreDataDiv.data('iframe') != ""){
					$readMoreClone.find('.iframe-container iframe').attr('src', $seeMoreDataDiv.data('iframe'));
				}else{
					$readMoreClone.find('.iframe-container').remove();
					$readMoreClone.find('.testimonials--grid-more-col_image').removeClass('col-md-9').addClass('col-md-4');
					$readMoreClone.find('.testimonials--grid-more-col_content').removeClass('col-md-3').addClass('col-md-8');
				}

				//setup read more div
				$readMoreClone.removeClass('clone');

				if(window_width > md_width){
					dataRowSelector = "md"
				}else if(window_width > sm_width){
					dataRowSelector = "sm"
				}

				if(dataRowSelector != ""){
					var rowNumber = $this.attr('data-' + dataRowSelector + '-row'),
						lastElOfRow = $('.testimonials--grid-item[data-' + dataRowSelector + '-row=' + rowNumber + ']').last();

					$readMoreClone.insertAfter(lastElOfRow)

					if($readMoreClone.find('.iframe-container iframe').length){
						$readMoreClone.find('.iframe-container iframe').show();
						vimeoPlayer = new Vimeo.Player($readMoreClone.find('.iframe-container iframe'));
						vimeoPlayer.play();
					}

					$readMoreClone.slideDown(300, function(){
						eqHeight($readMoreClone.find(' > div'))
					});

					$("html, body").animate({ scrollTop: $readMoreClone.offset().top },800);
				}
				return false;
			}
		});

		$('.testimonials').on('click','.testimonials--grid-more .close-more', function(){
			var parent = $(this).parents('.testimonials--grid-more');
			parent.slideUp(300,function(){
				$(this).remove();
			});
		});

	}

	// slick carousel
	if ($('body').hasClass('home')) {
		$('.carousel-container').on('init', function() {
			var $thisSlider = $(this);
			if ($thisSlider.find('div.slick-track div.slick-active div.vimeoEmbed').length > 0) {
				var $thisSlide = $thisSlider.find('div.slick-track div.slick-active div.vimeoEmbed');
				var vimeoIframeID = $thisSlide.attr('data-vimeo-id');
				if (typeof vimeoIframeID != 'undefined') {
					vimeoDivID = $thisSlide.attr('id');
					var vimeoPlayer = new Vimeo.Player(vimeoDivID);
					vimeoPlayer.on('play', function() {
						$('.carousel-container').slick('slickPause');
					});
					vimeoPlayer.on('pause', function() {
						$('.carousel-container').slick('slickPlay');
					});
					vimeoPlayer.on('ended', function() {
						$('.carousel-container').slick('slickPlay');
					});
				}
			}
		});

		$(".carousel-container").slick({
			dots: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			focusOnSelect:true,
			swipeToSlide: true,
			autoplay: false,
			autoplaySpeed: 15000,
			pauseOnHover: false
		});
		$('.carousel-container').slick('slickPlay');

		function maxHeightVimeo() {
			if ($('.carousel-container .vimeoEmbed').length > 0) {
				var maxSlideHeight = 0;
				$('.carousel-slide').each(function() {
					if ($(this).children('.vimeoEmbed').length == 0) {
						var thisHeight = $(this).outerHeight();
						if (maxSlideHeight < thisHeight) maxSlideHeight = thisHeight;
					}
				});
				if (maxSlideHeight > 0) {
					$('.carousel-slide .vimeoEmbed').height(maxSlideHeight);
				}
			}
		}
		maxHeightVimeo();
		$(window).on('resize', function() {
			maxHeightVimeo();
		});
	}
	else {
		$(".carousel-container").slick({
			dots: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			focusOnSelect:true,
			swipeToSlide: true
		});
	}

	$('a.gotoSlide').on('click', function() {
		var slider = '#' + $(this).attr('data-slider'),
		gotoSlide = $(this).attr('data-slide');
		$('.special-offers-aside__links a.current').removeClass('current');
		$(slider).slick('slickGoTo', gotoSlide);
		$(this).addClass('current');

		$('.special-offers-aside__cta a.current').removeClass('current');
		$('.special-offers-aside__cta a[data-slide=' + gotoSlide + ']').addClass('current');

		return false;
	});
	$('.carousel-container').on('afterChange', function(event, slick, currentSlide, nextSlide) {
		var $thisSlide = $(slick.$slides.get(currentSlide));
		var slideNumber = $thisSlide.attr('data-slide');
		var parentRel = $thisSlide.parents('.carousel-container').attr('rel');
		if (typeof parentRel != 'undefined') {
			$('ul.' + parentRel).find('li a.current').removeClass('current');
			$('ul.' + parentRel).find('li a[data-slide=' + slideNumber + ']').addClass('current');
			if (parentRel == 'special-offers-aside__links') {
				var linksRel = parentRel.replace('__links', '__cta');
				$('div.' + linksRel).find('a.current').removeClass('current');
				$('div.' + linksRel).find('a[data-slide=' + slideNumber + ']').addClass('current');

				var mobileLinksRel = parentRel.replace('__links', '__mobile-cta');
				$('div.' + mobileLinksRel).find('a.current').removeClass('current');
				$('div.' + mobileLinksRel).find('a[data-slide=' + slideNumber + ']').addClass('current');
			}
		}
	});
	$('.carousel-container').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
		var $thisSlide = $(slick.$slides.get(currentSlide));
		var slideNumber = $thisSlide.attr('data-slide');
		var parentRel = $thisSlide.parents('.carousel-container').attr('rel');
		if (typeof parentRel != 'undefined') {
			if (parentRel == 'special-offers-aside__links') {
				var $vimeoIframe = $($thisSlide.children('div.vimeoEmbed'));
				if (typeof $vimeoIframe != 'undefined') {
					if ($vimeoIframe.length > 0) {
						var vimeoPlayer = new Vimeo.Player($vimeoIframe.attr('id'));
						vimeoPlayer.pause();
					}
				}
			}
		}

		var $nextSlide = $(slick.$slides.get(nextSlide));
		var nextSlideNumber = $nextSlide.attr('data-slide');
		var nextParentRel = $nextSlide.parents('.carousel-container').attr('rel');
		if (typeof nextParentRel != 'undefined') {
			if (nextParentRel == 'special-offers-aside__links') {
				var $nextVimeoIframe = $($nextSlide.children('div.vimeoEmbed'));
				if (typeof $nextVimeoIframe != 'undefined') {
					if ($nextVimeoIframe.length > 0) {
						var nextVimeoPlayer = new Vimeo.Player($nextVimeoIframe.attr('id'));
						nextVimeoPlayer.pause();
						nextVimeoPlayer.on('play', function() {
							$('.carousel-container').slick('slickPause');
						});
						nextVimeoPlayer.on('pause', function() {
							$('.carousel-container').slick('slickPlay');
						});
						nextVimeoPlayer.on('ended', function() {
							$('.carousel-container').slick('slickPlay');
						});
					}
				}
			}
		}
	});
	$('.arrow-mobile').on('click touchstart', 'a', function() {
		var slider = $(this).attr('data-slider');
		if (typeof slider != 'undefined') {
			var $slider = $('#' + slider);
			if ($slider.length > 0) {
				if ($(this).hasClass('arrow-mobile__left')) {
					$slider.slick('slickPrev');
				}
				else if ($(this).hasClass('arrow-mobile__right')) {
					$slider.slick('slickNext');
				}
			}
		}
		return false;
	});

	$('.equal-height').matchHeight({property:"min-height"});

	if( $('.custom-select').length )
		$.jStyling.createSelect($('.custom-select'));

	$('.tab-menu').find('a').on('click', function(e){
		e.preventDefault();
		$('.tab-menu').find('a').removeClass('active')
		$(this).addClass('active');
		var $link = $(this).attr('data-tab');
		$(".tab").each(function() {

			var $tabLink = $(this).attr('id');
			if ($link == $tabLink) {
				$(".tab").hide().removeClass('tab--open');
				$(this).fadeIn(300).addClass('tab--open');
			}
		});
		if ($(this).parents('.hero-popup__inside').length > 0)  {
			// this is from the homepage, push some more data to GTM
			var selectedTabName = $(this).html();
			dataLayer.push({
				event: 'heroPopup',
				heroMenu: selectedTabName
			});
		}
	});

	$('.seo-section .seo-section__read-more').on('click', function() {
		$(this).hide();
		$('.seo-section .seo-section__read-less').show();
		$('.seo-section__text-expandable').css('display', 'inline');
		return false;
	});
	$('.seo-section .seo-section__read-less').on('click', function() {
		$(this).hide();
		$('.seo-section .seo-section__read-more').show();
		$('.seo-section__text-expandable').css('display', 'none');
		return false;
	});

	$('#seo-textarea-section .seo-textarea-section__read-more').on('click', function() {
		$(this).hide();
		$('#seo-textarea-section .seo-textarea-section__read-less').show();
		$('.seo-textarea-section__text-expandable').css('display', 'inline');
		return false;
	});
	$('#seo-textarea-section .seo-textarea-section__read-less').on('click', function() {
		$(this).hide();
		$('#seo-textarea-section .seo-textarea-section__read-more').show();
		$('.seo-textarea-section__text-expandable').css('display', 'none');
		return false;
	});

	/* home and land top area read more / read less handling */
	$('.article__read-more').on('click', function() {
		$(this).hide();
		$('.article__read-less').show();
		$('.article__text-expandable').css('display', 'inline');
		return false;
	});
	$('.article__read-less').on('click', function() {
		$(this).hide();
		$('.article__read-more').show();
		$('.article__text-expandable').css('display', 'none');
		return false;
	});

	/* search */
	$('.search-form').on('submit', function() {
		var searchTerm = $(this).children('input[name=searchTerm]').val();
		if (searchTerm.length > 0) {
			document.location = '/search/?query=' + searchTerm;
		}
		return false;
	});

	function searchUpdatePagination(selectedTab) {
		var $searchStats = $('#searchStats'),
			$newsStats   = $('#newsStats'),
			$pagination  = $('ul.pagination-widget');

		if (selectedTab == '#tab-allpages') {
			var currentPage  = $searchStats.attr('data-current-page'),
				totalPages   = $searchStats.attr('data-total-pages'),
				totalResults = $searchStats.attr('data-total-results'),
				linkClass    = 'search-pagination';
			$('#allPagesCount').html(totalResults);
		}
		else if (selectedTab == '#tab-news') {
			var currentPage  = $newsStats.attr('data-current-page'),
				totalPages   = $newsStats.attr('data-total-pages'),
				totalResults = $newsStats.attr('data-total-results'),
				linkClass    = 'news-pagination';
		}
		currentPage  = parseInt(currentPage);
		totalPages   = parseInt(totalPages);
		$pagination.html('');

		if (totalPages > 0) {
			for (i = 1; i <= totalPages; i++) {
				var liClass = '';
				if (i == currentPage) liClass = ' class="on"';
				$pagination.append('<li' + liClass + '><a href="#" class="' + linkClass + '" data-page-num="' + i + '">' + i + '</a></li>');
				if (i < totalPages) {
					$pagination.append('<li><span>&bull;</span></li>');
				}
			}
		}
	}

	if ($('#searchStats').length > 0) {
		searchUpdatePagination('#tab-allpages');
	}

	$('ul.pagination-widget').on('click', 'a.search-pagination', function() {
		// perform the search but with the next page of search results
		var page = $(this).attr('data-page-num'),
			searchTerm = $('#searchTerm').val();
		fadeLoader('in');
		$.ajax({
			type: 'POST',
			url: "/ajax/search.php?query=" + encodeURIComponent(searchTerm),
			data: ({ page: page }),
			dataType: 'html',
			success: function(html) {
				$('.search-results #tab-allpages').html(html);
				$('.tabs-selector a[href=#tab-allpages]').trigger('click');
				searchUpdatePagination('#tab-allpages');
				fadeLoader('out');
			}
		});
		return false;
	});

	$('ul.pagination-widget').on('click', 'a.news-pagination', function() {
		// perform the news search but with the next page of search results
		var page = $(this).attr('data-page-num'),
			searchTerm = $('#searchTerm').val();
		fadeLoader('in');
		$.ajax({
			type: 'POST',
			url: "/pages/components/search_news.php?=" + encodeURIComponent(searchTerm),
			data: ({ page: page }),
			dataType: 'html',
			success: function(html) {
				$('.search-results #tab-news').html(html);
				$('.tabs-selector a[href=#tab-news]').trigger('click');
				searchUpdatePagination('#tab-news');
				fadeLoader('out');
			}
		});
		return false;
	});

	$('.tabs-selector').on('click', 'a', function()  {
		var selectedTab = $(this).attr('href');
		$('.tabs-selector li.on').removeClass('on');
		$('.search-results .tab.current').removeClass('current');
		$(selectedTab).addClass('current');
		$(this).parent('li').addClass('on');

		if (selectedTab == '#tab-allpages' || selectedTab == '#tab-news') {
			// update the pagination numbering
			searchUpdatePagination(selectedTab);
			$('.pagination-widget').show();
		}
		else $('.pagination-widget').hide();

		setTimeout(function() {
			$(window).lazyLoadXT();
		}, 50);

		return false;
	});

	if ($('#homeStats').length > 0) {
		var $homeStats = $('#homeStats'),
			searchTotalHomes = $homeStats.attr('data-total-results');
		$('#homesCount').html(searchTotalHomes);
	}
	if ($('#newsStats').length > 0) {
		var $newsStats = $('#newsStats'),
			searchTotalNews = $newsStats.attr('data-total-results');
		$('#newsPostsCount').html(searchTotalNews);
	}

	if ($('#news-rollLayout').length > 0) {
		$('.section-news__list').waypoint(function(direction) {
			var $newsRollSidebar = $('.section-news__aside--secondary');
			if (direction == 'down') {
				$newsRollSidebar.addClass('sticky');
			}
			else {
				$newsRollSidebar.removeClass('sticky');
			}
		}, {
			offset: 25
		});
		$('.section-news__list').waypoint(function(direction) {
			var $newsRollSidebar = $('.section-news__aside--secondary');
			if (direction == 'down') {
				$newsRollSidebar.addClass('sticky-bottom');
			}
			else {
				$newsRollSidebar.removeClass('sticky-bottom');
			}
		}, {
			offset: function() {
				var $newsRoll = $('.section-news__list'),
					$newsRollSlider = $('.section-news__aside--secondary');
					offset = -1 * ($newsRoll.outerHeight() - $newsRollSlider.outerHeight() - 25);
				return offset;
			}
		});
	}

	if ($('body').hasClass('search')) {
		$searchSidebar = $('.search-sidebar');
		$(window).on('scroll', function() {
			var $sidebar           = $searchSidebar.children('sidebar'),
				sidebarOuterHeight = $sidebar.outerHeight(),
				sidebarBottom      = Math.round($searchSidebar.offset().top) + sidebarOuterHeight,
				windowScroll       = document.body.scrollTop,
				scrollHandler      = true,
				$mainPart          = $('.main-part'),
				$mainPartLeft      = $('section.main-part__left');

			if ($mainPart.length > 0) {
				var mainPartHeight = $mainPart.outerHeight();
				if (sidebarOuterHeight >= mainPartHeight) {
					scrollHandler = false;
					if ($sidebar.css('position') != 'relative') $sidebar.css('position', 'relative');
				}
			}

			if (scrollHandler == true) {
				if ($sidebar.css('position') == 'relative') $sidebar.css('position', '');
				if ($('.search-results').length > 0) {
					var searchResultsBottom = Math.round($mainPartLeft[0].getBoundingClientRect().top) + Math.round($mainPartLeft[0].getBoundingClientRect().height),
						searchSidebarBottom = Math.round($sidebar[0].getBoundingClientRect().top) + Math.round($sidebar[0].getBoundingClientRect().height);

					if (searchSidebarBottom >= searchResultsBottom) {
						if (Math.round($sidebar[0].getBoundingClientRect().top) > Math.round($searchSidebar.offset().top)) $sidebar.removeClass('sticky-bottom');
						else $sidebar.addClass('sticky-bottom');
					}
					else {
						$sidebar.removeClass('sticky-bottom');
					}
				}
				else {
					$sidebar.css({ position: 'relative' });
				}
			}
		});
	}

	if ($('body').attr('id') == 'news-postLayout') {
		var $newsPostSidebar = $('.news-post-sidebar'),
			$newsPostColumn = $('.news-post'),
			$newsPost = $('.article--news__body');

		$(window).on('scroll', function() {
			var sidebarBottom = $newsPostSidebar.position().top + $newsPostSidebar.children('aside').outerHeight() + 55,
				articleNewsTop = $newsPostColumn.offset().top,
				articleNewsBottom = $newsPost.offset().top + $newsPost.outerHeight(),
				windowScroll = document.body.scrollTop;
			if (windowScroll > articleNewsTop) {
				$newsPostSidebar.children('aside').addClass('sticky');
			}
			else {
				$newsPostSidebar.children('aside').removeClass('sticky');
			}

			if ((windowScroll + sidebarBottom - 1) > articleNewsBottom) {
				$newsPostSidebar.children('aside').addClass('sticky-bottom');
			}
			else {
				$newsPostSidebar.children('aside').removeClass('sticky-bottom');
			}
		});
	}

	if ($('body').attr('id') == 'productLayout') {
		var $viewPackages = $('.section-home__aside .widget-enquiry'),
			$stickyBar = $('.section-home__sticky-bar'),
			$containerSecondary = $('.section-design .container-secondary'),
			$introBg = $('.intro-bg');

		$(window).on('scroll', function() {
			if ($viewPackages.length > 0) {
				var viewPackagesBottom = $viewPackages.offset().top + $viewPackages.outerHeight(),
					containerSecondaryBottom = $containerSecondary.offset().top + $containerSecondary.outerHeight(),
					introBgBottom = $introBg.offset().top + $introBg.outerHeight(),
					windowScroll = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

				if ($(window).width() > 1024) {
					if ((windowScroll - 1) > (introBgBottom - 99)) {
						$viewPackages.addClass('sticky');
					}
					else {
						if (!$viewPackages.hasClass('sticky-bottom')) {
							$viewPackages.removeClass('sticky');
						}
					}

					if ($viewPackages.hasClass('sticky')) {
						// check to see if this needs to stick to the bottom of the container
						// if (windowScroll < (containerSecondaryBottom - 173 - $viewPackages.outerHeight())) {
						if (windowScroll < (containerSecondaryBottom - $viewPackages.outerHeight() - 25)) {
							$viewPackages.removeClass('sticky-bottom');
						}
						else {
							var scrollTrigger = (viewPackagesBottom + 80);
							if (scrollTrigger > containerSecondaryBottom) {
								$viewPackages.addClass('sticky-bottom');
							}
							else if (!$('html').hasClass('ie10')) {
								$viewPackages.removeClass('sticky-bottom');
							}
						}
					}
				}
				else {
					$viewPackages.removeClass('sticky').removeClass('sticky-bottom');
				}
			}
			else if ($stickyBar.length > 0) {
				var currentWindowScroll = $(window).scrollTop(),
					introBottom = $introBg.offset().top + $introBg.height();
				if (currentWindowScroll > introBottom) {
					$stickyBar.addClass('sticky');
				}
				else {
					$stickyBar.removeClass('sticky');
				}
			}
		});
	}else if ($('body').hasClass('general') && $('.widget-enquiry').length) {
		var $widgetGeneral = $('.widget-enquiry'),
			$widgetGeneralContainer = $widgetGeneral.parents(".container__widget"),
			$containerSecondary = $('.section-sticky-limit'),
			$introBg = $('.intro-primary');

		$(window).on('scroll', function() {
			var widgetGeneralBottom = $widgetGeneral.offset().top + $widgetGeneral.outerHeight(),
				containerSecondaryBottom = $containerSecondary.offset().top + $containerSecondary.outerHeight(),
				introBgBottom = $introBg.offset().top + $introBg.outerHeight(),
				windowScroll = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

			if ($(window).width() > 1024) {
				if ((windowScroll - 1) > (introBgBottom - 99)) {
					$widgetGeneralContainer.addClass('sticky');
				}
				else {
					if (!$widgetGeneralContainer.hasClass('sticky-bottom')) {
						$widgetGeneralContainer.removeClass('sticky');
					}
				}

				if ($widgetGeneralContainer.hasClass('sticky')) {
					// check to see if this needs to stick to the bottom of the container
					// if (windowScroll < (containerSecondaryBottom - 173 - $widgetGeneral.outerHeight())) {
					if (windowScroll < (containerSecondaryBottom - $widgetGeneral.outerHeight() - 25)) {
						$widgetGeneralContainer.removeClass('sticky-bottom');
					}
					else {
						var scrollTrigger = (widgetGeneralBottom + 80);
						if (scrollTrigger > containerSecondaryBottom) {
							$widgetGeneralContainer.addClass('sticky-bottom');
						}
						else if (!$('html').hasClass('ie10')) {
							$widgetGeneralContainer.removeClass('sticky-bottom');
						}
					}
				}
			}
			else {
				$widgetGeneralContainer.removeClass('sticky').removeClass('sticky-bottom');
			}
		});
	}

	$('.main-part').on('click','a.houses-filter__footer-mobile', function(e) {
		e.preventDefault();
		if($('div.houses-filter__sidebar-mobile').hasClass("open")){
			$('div.houses-filter__footer-sticky').removeClass('open');

			$('div.houses-filter__sidebar-mobile').fadeOut(function() {
				$(this).removeClass('open').css('display', '');
				$("body").removeClass('no-scroll');
			});
		}else{
			$('div.houses-filter__footer-sticky').addClass('open');
			$('div.houses-filter__sidebar-mobile').fadeIn(function() {
				$(this).addClass('open').css('display', '');
				$("body").addClass('no-scroll');
			});
		}

		return false;
	});
	$('.houses-filter').on('click', 'a.close', function() {
		$('div.houses-filter__footer-sticky').removeClass('open');

		$('div.houses-filter__sidebar-mobile').fadeOut(function() {
			$(this).removeClass('open').css('display', '');

			$("body").removeClass('no-scroll');
		});
		return false;
	});

	function windowResize() {
		var $housesFilter = $('.houses-filter'),
			$housesFilterSidePanel = $housesFilter.find('.houses-filter__sidepanel'),
			windowWidth = $(window).width();
		if ($housesFilter.length > 0) {
			if (windowWidth < 1200) {
				$housesFilterSidePanel.removeClass('sticky-bottom').removeClass('sticky').children('.houses-filter__sidebar').css({
					top:    '',
					bottom: '',
					width:  'auto'
				});
			}
			Waypoint.refreshAll();
		}
		navigationHeight();
	}

	$(window).on('resize', function() {
		windowResize();
	});

	// feed <-> map switching in product range pages
	$('.houses-filter__panel-switch').on('click', 'a', function() {
		if (!$(this).hasClass('active') && !$(this).parents('.houses-filter__panel-switch').hasClass('ajaxing')) {
			$(this).addClass('active');
			var rel = $(this).attr('data-rel');
			$('.houses-filter__top-panel a.active').removeClass('active');
			$('.houses-filter__top-panel .houses-filter__' + rel + '-switch').addClass('active');
			$('.houses-filter__controls').children(':not(.hidden)').addClass('hidden');
			$('.houses-filter__' + rel + '-controls').removeClass('hidden');
			$('.tabs-tertiary__tab.tabs-tertiary__tab-active').removeClass('tabs-tertiary__tab-active');
			$('#product-range-tab__' + rel).addClass('tabs-tertiary__tab-active');
			Waypoint.refreshAll();

			if($(".filters").hasClass("map_not_initialised")){
				if ($('.houses-filter__map-switch').hasClass('active')) {
					$(this).parents('.houses-filter__panel-switch').addClass('ajaxing');
					$(".filters").removeClass("map_not_initialised");
					initMaps();
				}
			}else{
				if($(".filters").hasClass("updated") ){
					$(".filters").removeClass("updated");
					$($('.filters input')[0]).trigger('change');
				}
			}
		}
		return false;
	});

	// Accordion
	$('.accordion__head a:not(.accordion-link), .accordion-secondary__head').on('click', function(e){
		e.preventDefault();

		var $this = $(this);
		$this
			.closest('[class*="__section"]')
			.toggleClass('accordion__section-expanded')
			.find(".accordion-secondary__body").slideToggle();
	});

	function initMaps() {
		if (googleMapsLoaded === false) {
			$.getScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBS5OKjycLiA0-wHe-QlGlVAeIoTqCrulA', function() {
				googleMapsLoaded = true;
				$.getScript('//henley.com.au/resources/js/vendor/infobox.js', function() {
					$.getScript('//henley.com.au/resources/js/vendor/js-marker-clusterer-gh-pages/src/markerclusterer.js', function() {
						startMaps();
					});
				});
			});
		}
	}

	$(window).on('load', function(){
		// Start images slider
		startImagesSlider();

		// Start maps
		if (
			$('[class*="map"]:not([class*="container"]):not([class*="sitemap"])').length
			&& !$('body').hasClass('house-and-land-landing-page')
			&& !$('body').hasClass('readybuilt-landing')
		) {
			var $map = $('#map');
			if ($map.length > 0) {
				var debounceTimer;
				if (isElementInViewport($map) == true) {
					initMaps();
				}

				$(window).on('scroll', function() {
					if (isElementInViewport($map) == true) {
						if (debounceTimer) {
							clearTimeout(debounceTimer);
						}
						debounceTimer = setTimeout(function() {
							initMaps();
						}, 500);
					}
				});
			}
		}

		if ($('body').hasClass('new-homes_series') || $('body').hasClass('readybuilt_lot')) {
			var hash = location.hash;
			if (typeof hash != 'undefined') {
				if (hash == '#360view') {
					$('a.icon-anchor-link-360').trigger('click');
				}
			}
		}
	});

	// Tabs functionality
	$('.tabs__nav a').on('click', function(e){
		e.preventDefault();

		var $this = $(this);
		var $target = $($this.attr('href'));

		$this
			.parent()
				.addClass('tabs__nav-active')
				.siblings()
					.removeClass('tabs__nav-active');

		$target
			.addClass('tabs__tab-active')
			.siblings()
				.removeClass('tabs__tab-active');

		setTimeout(function() {
			$(window).scroll();
		}, 50);

	});

	$('.houses-filter__sidebar h3').on('click', function(){
		$('.houses-filter__sidebar').toggleClass('houses-filter__sidebar-expanded');
	});

	// Start images slider
	function startImagesSlider() {
		$('.slider-images').each(function(){
			var $sliderImages = $(this);
			var $sliderImagesContainer = $sliderImages.find('.slides');
			var $sliderCurrent = $sliderImages.find('.slide-images__number');
			var $sliderTotal = $sliderImages.find('.slider-images__total');
			var $totalSlides = $sliderImagesContainer.children().length;
			var $totalTextSlides = $sliderImagesContainer.children().not('.go-to-other-gallery').length;
			$sliderTotal.text($totalTextSlides);
			$sliderImagesContainer.slick({});

			$sliderImagesContainer.on('afterChange', function(event, slick, currentSlide){
			  	$sliderCurrent.text(currentSlide + 1);
			  	$(window).scroll();

			  	// get the background image for this slide, if possible
			  	var $currentSlide = $sliderImages.find('.slick-current');
			  	var bgURL = $currentSlide.children('div').css('background-image');
			  	bgURL = /^url\((['"]?)(.*)\1\)$/.exec(bgURL);
			  	bgURL = bgURL ? bgURL[2] : "";

			  	if (bgURL.length > 0) {
			  		bgURL = bgURL.substring(bgURL.lastIndexOf('/') + 1);
			  	}

			  	dataLayer.push({
			  		formType: 'productImage',
			  		formId: $('.section-home__head h1').html(),
			  		event: 'productImageView',
			  		imageNumber: currentSlide,
			  		imageName: bgURL
			  	});
			});

			//new homes detail
			if($("body").hasClass('new-homes_series') || $("body").hasClass('house-and-land') || $("body").hasClass('display-for-sale_detail') ){
				var end_of_slides = false,
					new_slider;
				$sliderImagesContainer.on('beforeChange', function(event, slick, currentSlide, nextSlide){
					var slider_sections = $("#gallerySection .section__body");
				  	if((currentSlide + 1 == $totalSlides && nextSlide == 0) || ($('#gallerySection .section__body.active .slick-slide[data-slick-index="'+nextSlide+'"]').hasClass("go-to-other-gallery") && currentSlide == 1)){
					  	new_slider = $('#gallerySection .section__body.active').next('.section__body');
					  	if($( "#gallerySection .section__body" ).index( new_slider ) == -1){
						  new_slider = $(slider_sections[0]);
					  	}
					  	new_slider.find(".slides").slick("slickGoTo", 0);
					  	$("#gallerySection .nav-secondary a[data-class='"+new_slider.data("class")+"']").click();
				  	}else if((currentSlide == 0 && nextSlide + 1 == $totalSlides) || ($('#gallerySection .section__body.active .slick-slide[data-slick-index="'+nextSlide+'"]').hasClass("go-to-other-gallery") && currentSlide == 0)){
					  	new_slider = $('#gallerySection .section__body.active').prev('.section__body');
					  	if($( "#gallerySection .section__body" ).index( new_slider ) == -1){
						  	new_slider = $(slider_sections[slider_sections.length - 1]);
					  	}
					  	var last_slide = new_slider.find(".slides .slick-slide:not(.slick-cloned)").not('.go-to-other-gallery').length - 1;
					  	new_slider.find(".slides").slick("slickGoTo", last_slide);
					  	$("#gallerySection .nav-secondary a[data-class='"+new_slider.data("class")+"']").click();
				  	}
				});
			}

			//estate
			if($("body").hasClass('estate')){
				if($('#gallerySection .slides li').length > 1){
					$('#gallerySection .slides').slick('slickSetOption', 'autoplaySpeed', '4000');
					$('#gallerySection .slides').slick('slickSetOption', 'autoplay', true, true);
				}
			}

			if($("body").hasClass('estate') || $("body").hasClass('display-centre-single')){
				if($('#gallerySection .slides .slide').length == 1){
					$('#gallerySection .slider-images__counter').hide();
				}
			}
		});
	}

	// Load AJAX data
	function loadAjax(filePath) {
		return $.ajax({
			'async': false,
			'global': false,
			'url': filePath
		}).responseJSON;
	}

	$('body').on('click', 'a.scrollTo', function() {
		var $thisObj = $(this),
			scrollTo = $thisObj.attr('data-scrollto'),
			offset;
		if (typeof scrollTo != 'undefined') {
			if ($('#' + scrollTo).length > 0) {
				offset = 30;
				// use position or offset?
				if ($thisObj.hasClass('scrollTo-offset')) {
					var scrollPos = $('#' + scrollTo).offset().top - offset;
				}
				else {
					var scrollPos = $('#' + scrollTo).offset().top - offset;
				}
				$('html, body').animate({
					scrollTop: scrollPos
				});
			}
		}
		return false;
	});
	$('body').on('click', 'a.scrollToBottom', function() {
		var scrollToElement = $(this).attr('data-scrollto'),
			offset;
		if (typeof scrollToElement != 'undefined') {
			if ($(scrollToElement).length > 0) {
				offset = 0;
				var scrollPos = $(scrollToElement).position().top + $(scrollToElement).outerHeight() - offset;
				$('html, body').animate({
					scrollTop: scrollPos
				});
			}
		}
		return false;
	});

	$('.section-home__read-more').on('click', function() {
		$(this).hide();
		$('.section-home__expandable').css({
			display: 'inline'
		});
		return false;
	});
	$('.section-home__read-less').on('click', function() {
		$(this).parent().hide();
		$('.section-home__read-more').css({
			display: 'inline'
		});
		return false;
	});

	function createCookie(name, value, days) {
	    var date, expires;
	    if (days) {
	        date = new Date();
	        date.setTime(date.getTime()+(days*24*60*60*1000));
	        expires = "; expires="+date.toGMTString();
	    } else {
	        expires = "";
	    }
	    document.cookie = name+"="+value+expires+"; path=/";
	}

	$('.widget-enquiry__favourite').on('click', function() {
		$(this).toggleClass('selected');

		var page_id = $(this).data('page-id'),
		    status = "no";

		if($(this).hasClass('selected')){
			createCookie("favourite_page_"+page_id, "yes", 1);
		}else{
			createCookie("favourite_page_"+page_id, "no", -1);
		}
		return false;
	});

	if($('body').hasClass('house-and-land') || $('body').hasClass('readybuilt') || $("body").hasClass('display-for-sale_detail')) {
		$(window).load(function() {
			var svgContainer = document.getElementById('svg1');

			if (typeof svgContainer != 'undefined' && svgContainer != null ) {
				var svgDoc = svgContainer.contentDocument;
				if (svgDoc != null) {
					if (typeof svgDoc == 'object') {
						$(svgDoc).find("g[id]").css('display','none');

						var svg_object = $("#"+svgContainer.id);

						if (svg_object.attr('data-layer-id')) {
								var layerID = svg_object.attr('data-layer-id').replace(/_/g, '_x5F_');
								$(svgDoc).find("#"+layerID).css('display','block');
							}
							ini_svgPanZoom('#svg1');
						$('.design__image').css('visibility', 'visible');
					}
				}
			}
		});
	}

	// floor plan size switching
	if($('.design__select-design').length > 0) {
		$(window).load(function() {
			var $floorplans = $('.design__image').children('.floorplan');
			$floorplans.each(function() {
				var svg_id = $(this).children('object').attr('id');
				$('.design__select a.disabled[data-page-id="' + svg_id.replace('svg', '') + '"]').removeClass('disabled');
				$('.zoom-in-modal .control__container select option[data-page-id=' + svg_id.replace('svg', '') + ']').removeClass('disabled');
				$('.design__image').css('visibility', 'visible');
			});
		});
	}

	$('.design__select-design').on('click', '.design__select a', function() {
		var page_id = $(this).data('page-id');

		if (!$(this).hasClass('disabled') && !$(this).hasClass('active')) {
			//SVG
			if (!$('html').hasClass('firefox') && !($('html').hasClass('ie')) && !($('html').hasClass('edge'))) {
				$('.design__image').css('visibility', 'hidden');
			}
			$('.design__image').children('.floorplan.active').removeClass('active');
			$('.design__image').children('#floorplan-' + page_id).addClass('active');

			$('.zoom-in-modal .center .right.block').css('visibility', 'hidden');
			$('.zoom-in-modal .center .right.block .floorplan.active').removeClass('active');
			$('#zoomfloorplan-' + page_id).addClass('active');

			//Change elements with class: change_on_series_home_click
			$('.change_on_series_home_click').removeClass('active');
			$('.change_on_series_home_click[data-page-id='+ page_id +']').addClass('active');
			$('.listPrice').addClass('hidden');
			$('.spend_check_div').addClass('hidden');
			$('.listPriceSpend').addClass('hidden');

			// change the print icon to point to the right PDF file
			var pdfLink = $('.change_on_series_home_click.active').children('a').attr('href');
			$('.design__inner-actions a.downloadPDF').attr('href', pdfLink);

			//zoom_in_select
			$(".zoom-in-modal .control__container select option[data-page-id=" + page_id + "]").prop('selected', true);

			//button
			$('.design__select li a.active').removeClass('active');
			$(this).addClass('active');

			// clear any selected choices and addons
			$('.choices_and_add_ons').find('a.togglePlan.selected').removeClass('selected');

			if ($('html').hasClass('ie') || /Edge/.test(navigator.userAgent)) {
				svgOnLoad('svg' + page_id);
				if ($('.zoom-in-modal').css('visibility') == 'visible') {
					svgOnLoad('zoom_svg' + page_id);
				}
			}
			if ($('html').hasClass('firefox')) {
				ini_svgPanZoom('#svg' + page_id);
				if ($('.zoom-in-modal').css('visibility') == 'visible') {
					svgOnLoad('zoom_svg' + page_id);
				}
			}
			$('.design__image').css('visibility', 'visible');
		}
		return false;
	});

	$('.zoom-in-modal .control__container').on('change', 'select', function() {
		var pageID = $(this).find(':selected').attr('data-page-id');
		$('.design__select-design a[data-page-id=' + pageID + ']').click();
	});

	function flipflopFloorplan(viewPort, flip) {
		$levelLayers = viewPort.children('g');
		if ($levelLayers.attr('id') == 'undefined') {
			$levelLayers = viewPort.children('g').children('g');
		}

		if ($levelLayers.length == 1) {
			$levelLayersNew = $levelLayers.children('g');
			$levelLayers = $levelLayersNew;
			delete $levelLayersNew;
		}
		if ($levelLayers.filter('g[id$="-ground-floor"], g[id$="-first-floor"]').length > 0) {
			// multilevel
			var $groundLevel = $levelLayers.filter('g[id$="-ground-floor"]'),
				$groundBase  = $groundLevel.children('g[id$="-base"]'),
				$groundFlip  = $groundLevel.children('g[id$="-flip"]');

			var $firstLevel = $levelLayers.filter('g[id$="-first-floor"]'),
				$firstBase  = $firstLevel.children('g[id$="-base"]'),
				$firstFlip  = $firstLevel.children('g[id$="-flip"]');

			var $dualLevel;
			if ($levelLayers.filter('g[id$="-dual-floor"]').length > 0) {
				$dualLevel = $levelLayers.filter('g[id$="-dual-floor"]');
			}
		}
		else if ($levelLayers.children('g[id$="-ground-floor"], g[id$="-first-floor"]').length > 0) {
			// multilevel
			var $groundLevel = $levelLayers.children('g[id$="-ground-floor"]'),
				$groundBase  = $groundLevel.children('g[id$="-base"]'),
				$groundFlip  = $groundLevel.children('g[id$="-flip"]');

			var $firstLevel = $levelLayers.children('g[id$="-first-floor"]'),
				$firstBase  = $firstLevel.children('g[id$="-base"]'),
				$firstFlip  = $firstLevel.children('g[id$="-flip"]');

			var $dualLevel;
			if ($levelLayers.filter('g[id$="-dual-floor"]').length > 0) {
				$dualLevel = $levelLayers.filter('g[id$="-dual-floor"]');
			}
		}
		else {
			// single level, does not have *-ground-floor or *-first-floor layers
			var $groundBase  = $levelLayers.filter('g[id$="-base"]'),
				$groundFlip  = $levelLayers.filter('g[id$="-flip"]');
			if ($groundBase.length == 0) {
				$groundBase = $levelLayers.children('g[id$="-base"]');
			}
			if ($groundFlip.length == 0) {
				$groundFlip  = $levelLayers.children('g[id$="-flip"]');
			}
		}
		var flipDisplay = 'none',
			baseDisplay = 'block';
		if (flip == 'flip') {
			// restore to base layer
			flipDisplay = 'block';
			baseDisplay = 'none';
		}
		$groundBase.attr('display', baseDisplay);
		$groundFlip.attr('display', flipDisplay);
		if (typeof $firstLevel != 'undefined') {
			$firstBase.attr('display', baseDisplay);
			$firstFlip.attr('display', flipDisplay);
		}
	}

	$('.choices_and_add_ons').on('click', 'a.togglePlan', function() {
		var $toggle       = $(this),
			toggleID      = $toggle.attr('id'),
			$toggleParent = $(this).parent(),
			svgID         = $toggle.attr('data-svg'),
			svgLayerID    = $toggle.attr('data-g-id'),
			flipOption    = $toggle.attr('data-floorplan-flip');

		var svgCont     = document.getElementById(svgID);

		if (typeof svgCont != 'undefined' && svgCont != null) {
			var svgDoc = svgCont.contentDocument;
			if (svgDoc != null) {
				if (typeof svgDoc == 'object') {
					var $viewPort     = $(svgDoc).find('g.svg-pan-zoom_viewport');
					if ($viewPort.length == 0) $viewPort = $(svgDoc).find('g[id^="viewport-"]');

					var $dualLevel
					if ($viewPort.find('g[id$="-dual-floor"]').length > 0) {
						$dualLevel     = $viewPort.find('g[id$="-dual-floor"]');
					}

					// unselect whichever option is already selected
					if ($('.choices_and_add_ons a.togglePlan.selected[id!="' + toggleID + '"]').length > 0) {
						$('.choices_and_add_ons a.togglePlan.selected[id!="' + toggleID + '"]').trigger('click');
					}

					if (typeof flipOption != 'undefined') {
						if ($toggle.hasClass('selected')) {
							flipflopFloorplan($viewPort, 'base');
							$toggle.removeClass('selected');
							$toggleParent.removeClass('selected');
						}
						else {
							flipflopFloorplan($viewPort, 'flip');
							$toggle.addClass('selected');
							$toggleParent.addClass('selected');
						}
					}
					else {
						var dualLayer      = false,
							$svgLayer      = false,
							$svgLayer2     = false;

						if ($toggle.attr('data-layer')) {
							if ($toggle.attr('data-layer') == 'dual') dualLayer = true;
						}

						if (dualLayer == true) {
							var svgGroundLayerID = $toggle.attr('data-g-ground-id'),
								svgFirstLayerID  = $toggle.attr('data-g-first-id'),
								$svgLayer        = $('#' + svgGroundLayerID, svgDoc),
								$svgLayer2       = $('#' + svgFirstLayerID, svgDoc);
						}
						else {
							var $svgLayer     = $('#' + svgLayerID, svgDoc);
						}

						if ($toggle.hasClass('selected')) {
							// turn off
							$svgLayer.attr('display', 'none');

							$toggle.removeClass('selected');
							$toggleParent.removeClass('selected');
							if (dualLayer) {
								if (typeof $dualLevel != 'undefined') {
									$dualLevel.children().each(function() {
										$(this).attr('display', 'none');
									});
								}
							}
						}
						else {
							// turn on
							$svgLayer.attr('display', 'block');
							if (dualLayer) {
								var floorplanTranslate = $('body').attr('data-floorplan-default');
								floorplanTranslate = parseInt(floorplanTranslate);
								if (floorplanTranslate < 1) floorplanTranslate = 175;

								// check if there's adjustment
								var $svgContainer = $('#' + svgID);
								if (typeof $svgContainer.attr('data-adjustment') != 'undefined') {
									var floorplanAdjustment = $svgContainer.attr('data-adjustment');
									if (floorplanAdjustment.length > 0) {
										floorplanAdjustment = parseInt(floorplanAdjustment);
										floorplanTranslate += floorplanAdjustment;
									}
								}

								if (typeof $dualLevel != 'undefined') {
									$svgLayer.attr('transform', 'translate(-' + floorplanTranslate + ')'); // left side
									$svgLayer2.attr('display', 'block').attr('transform', 'translate(' + floorplanTranslate + ')');
									$dualLevel.attr('display', 'block');
								}
							}
							$toggle.addClass('selected');
							$toggleParent.addClass('selected');
						}
					}

					var svgPZ = svgPanZoom('#' + svgID);
					if ($('html').hasClass('mobile')) {
						if ($('#' + svgID).attr('data-storeys') == '1') {
							svgPZ.reset();
						}
					}
					else {
						svgPZ.reset();
					}
					if (svgID == 'svg30774') {
						svgPZ.zoomBy(0.9);

					}
					if (svgID == 'svg31649' || svgID == 'svg31643' || svgID == 'svg32729' || svgID == 'svg32731') {
						svgPZ.zoomBy(0.8);

					}




				}
			}
		}

		var homeDesign = $('.change_on_series_home_click.active').find('.design__aside').children('h5').html();
		var showHide;
		if ($toggle.hasClass('selected')) showHide = 'show';
		else showHide = 'hide';
		dataLayer.push({
			formType: 'choicesAndAddons',
			formId: 'choicesAndAddons',
			homeDesign: homeDesign,
			floorplanOption: svgLayerID,
			event: showHide
		});

		return false;
	});

	$('.zoom_choices_and_add_ons').on('click', 'a.togglePlan', function() {
		var thisID = $(this).attr('id');
		$('#' + thisID.replace('zoomPlanOption-', 'planOption-')).trigger('click');
		return false;
	});

	$('.design__inner-actions').on('click', 'a.flipFloorplan', function() {
		var $currentPanel = $('.this-floorplan.active');
		if ($currentPanel.length > 0) {
			var $flipClick = $currentPanel.find('.accordion__body .flipFloorplan').trigger('click');
		}
		return false;
	});

	if ($('.design__inner').length > 0) {
		if ($('html').hasClass('safari') || $('html').hasClass('firefox')) {
			if ($('ul.design__select').length > 0) {
				var currentID = $('ul.design__select li a.active').attr('data-page-id');
				svgOnLoad('svg' + currentID);
			}
		}
	}

	$('a.virtual-tour__load').on('click', function() {
		var $this            = $(this),
			$iframeContainer = $this.parents('.virtual-tours').find('.iframe-container'),
			$iframe          = $iframeContainer.children('iframe'),
			iframeSrc        = $iframe.attr('data-load-src');

		$iframe.attr('src', iframeSrc);
		$iframeContainer.removeClass('hidden');
		$this.addClass('fadeHide');
		var setHide = setTimeout(function() {
			$this.addClass('hidden').removeClass('fadeHide');
		}, 600);
		return false;
	});

	// subscribe form in footer
	$('#footerSubscribeForm').validate({
		errorClass: 'error',
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $('#footerSubscribeForm').serialize(),
				$submitButton = $('#footerSubscribeForm input[type=submit]'),
				submitValue = $submitButton.val();
			serializedForm += "&url=" + window.location;
			$submitButton.prop('disabled', true).val('Submitting...');
			$.ajax({
				type: 'POST',
				url: '/ajax/subscribe.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).val(submitValue);
					if (response.status == 'ok') {
						dataLayer.push({
							formType: 'footerSubscribeForm',
							formId: 'footerSubscribeForm',
							event: 'formSubmissionSuccess'
						});

						$(form)[0].reset();
						$('.subscribe-row').children('p').after('<p>' + response.message + '</p>');
					}
					else {
						alert(response.message);
					}
				}
			});
		},
	});

	$('.custom-booking').on('click', 'a.button', function() {
		var $formShell   = $('.section-info__group-form-shell'),
			$formSelect  = $('.custom-booking'),
			selectedForm = $(this).attr('href');
		$formSelect.find('a.button').removeClass('active');
		$(this).addClass('active');
		$formShell.children('.section-info__group-form-container').removeClass('active');
		$formShell.children(selectedForm).addClass('active');
		return false;
	});

	$('.section-center__group-team__content').on('click', 'a.how-can-we-help', function() {
		$('#make-an-enquiry-form-container').addClass('active');
		var $thisObj = $(this),
			scrollTo = $thisObj.attr('data-scrollto'),
			offset;
		if (typeof scrollTo != 'undefined') {
			if ($('#' + scrollTo).length > 0) {
				offset = 30;
				// use position or offset?
				if ($thisObj.hasClass('scrollTo-offset')) {
					var scrollPos = $('#' + scrollTo).offset().top - offset;
				}
				else {
					var scrollPos = $('#' + scrollTo).offset().top - offset;
				}
				$('html, body').animate({
					scrollTop: scrollPos
				});
			}
		}
		return false;

	});

	$('.section-info__group-form-select').on('click', 'a.button', function() {
		var $formShell   = $('.section-info__group-form-shell'),
			$formSelect  = $('.section-info__group-form-select'),
			selectedForm = $(this).attr('href');
		$formSelect.find('a.button').removeClass('active');
		$(this).addClass('active');
		$formShell.children('.section-info__group-form-container').removeClass('active');
		$formShell.children(selectedForm).addClass('active');
		return false;
	});
	if ($('body').hasClass('display-centre-single')) {
		if ($('#d365o-appointment').length > 0) {
			setTimeout(function () {
				$('#inputLocation').trigger('change');
			}, 2000);

			if (window.location.hash) {
				var hash = window.location.hash.substring(1);
				if (hash = 'book-appointment') {
					$('.form-select__button-list').find('a[href=#book-an-appointment-form-container]').trigger('click');
				}
			}
		}
	}

	// booking an appointment from a display centre page
	$('#bookAppointment').validate({
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).parents('.form-group').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).parents('.form-group').removeClass('error');
		},
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $('#bookAppointment').serialize(),
				$submitButton = $('#bookAppointment button[type=submit]'),
				submitValue = $submitButton.html();
			$submitButton.prop('disabled', true).html('Submitting...');

			var ajaxSubmit = '/ajax/api_contact.php';
			if ($('body').hasClass('henley-sa')) ajaxSubmit = '/ajax/contact.php';
			$.ajax({
				type: 'POST',
				url: ajaxSubmit,
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).html(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();

						var $thankyouDiv = $('#bookAppointment').next('.form-booking__thank-you');
						$thankyouDiv.children('h2').html(response.message);
						$thankyouDiv.removeClass('hidden');

						dataLayer.push({
	                        formType: 'displayEnquiry',
	                        formId: 'bookAppointment',
	                        event: 'formSubmissionSuccess'
	                    });

						dataLayer.push({
							formType: 'bookAppointment',
							formId: 'bookAppointment',
							enquiringAbout: $('#inputEnquiry').val(),
							event: 'formSubmissionSuccess'
						});
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});

	// ready built enquiry on form when there's no ready built homes available
	$('#readyBuiltEnquiry-noResults').validate({
		rules: {
			email: {
					required: true,
					email: true,
				}
		},
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).parents('.form-group').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).parents('.form-group').removeClass('error');
		},
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $('#readyBuiltEnquiry-noResults').serialize(),
				$submitButton = $('#readyBuiltEnquiry-noResults button[type=submit]'),
				submitValue = $submitButton.html();
			$submitButton.prop('disabled', true).html('Submitting...');
			$('#readyBuiltEnquiry-noResults p').remove();

			$.ajax({
				type: 'POST',
				url: '/ajax/api_contact.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).html(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();
						$('#readyBuiltEnquiry-noResults').hide();
						$('.houseAndLandEnquiry-thankyou').removeClass('hidden');

						('<p>' + response.message + '</p>');
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});

	// ready built enquiry on form when there's no ready built homes available
	$('#readyBuiltEnquiry-noResults-sa').validate({
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).parents('.form-group').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).parents('.form-group').removeClass('error');
		},
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $('#readyBuiltEnquiry-noResults-sa').serialize(),
				$submitButton = $('#readyBuiltEnquiry-noResults-sa button[type=submit]'),
				submitValue = $submitButton.html();
			$submitButton.prop('disabled', true).html('Submitting...');
			$('#readyBuiltEnquiry-noResults-sa p').remove();

			$.ajax({
				type: 'POST',
				url: '/ajax/contact.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).html(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();
						$('#readyBuiltEnquiry-noResults-sa').hide();
						$('.houseAndLandEnquiry-thankyou-sa').removeClass('hidden');

						('<p>' + response.message + '</p>');
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});


	// sidebar subscribe function
	$('#sidebarSubscribe').validate({
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).addClass('error').prev('label').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).removeClass('error').prev('label').removeClass('error');
		},
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $('#sidebarSubscribe').serialize(),
				$submitButton = $('#sidebarSubscribe button[type=submit]'),
				submitValue = $submitButton.html();
			$submitButton.prop('disabled', true).html('Submitting...');
			serializedForm += "&url=" + window.location;
			$.ajax({
				type: 'POST',
				url: '/ajax/subscribe.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					$submitButton.prop('disabled', false).html(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();
						if ($('#sidebarSubscribe p').length == 0) {
							$('#sidebarSubscribe').prepend('<p>' + response.message + '</p>');
						}
						else {
							$('#sidebarSubscribe p').html(response.message);
						}
						dataLayer.push({
							formType: 'sidebarSubscribe',
							formId: 'sidebarSubscribe',
							event: 'formSubmissionSuccess'
						});
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});

	// cta contact form
	$('.ctaForm').each(function(){
		
		// Only apply the special checkbox rule and method if this form has .tradie-form
		var isTradieForm = $(this).hasClass('tradieForm');

	    if (isTradieForm) {
	        // Define the custom method only once (if not already added)
	        if (!$.validator.methods.requireFromCheckboxGroup) {
	            $.validator.addMethod("requireFromCheckboxGroup", function(value, element, options) {
	                var $fields = $(options);
	                return $fields.filter(":checked").length > 0;
	            }, "Please select at least one area.");
	        }
	    }
		
		
		$(this).validate({
			errorClass: 'error',
			highlight: function(element, errorClass) {
				$(element).addClass('error');
				$(element).parents('.form-contact__field-container').addClass('error');
			},
			unhighlight: function(element, errorClass) {
				$(element).removeClass('error');
				$(element).parents('.form-contact__field-container').removeClass('error');
			},
			errorPlacement: function(error, element) {
            if (element.attr("name") == "areas_serviced[]") {
                error.insertAfter(element.closest('.form-group'));
            }
        },
			
			// Conditionally add the rule for areas_serviced[]
			rules: isTradieForm ? {
	            'areas_serviced[]': {
	                requireFromCheckboxGroup: "input[name='areas_serviced[]']"
	            }
	        } : {},
			
			submitHandler: function(form) {
				var serializedForm = $(form).serialize(),
					$thisForm = $('#' + this.currentForm.id),
					$submitButton = $thisForm.find('input[type=submit]'),
					submitValue = $submitButton.val(),
					ajaxCall = $thisForm.attr('data-ajax-url');

				fadeLoader('in');
				$submitButton.prop('disabled', true).val('Submitting...');
				$.ajax({
					type: 'POST',
					url: '/ajax/' + ajaxCall + '.php',
					data: serializedForm,
					dataType: 'json',
					success: function(response) {
						fadeLoader('out');
						$submitButton.prop('disabled', false).val(submitValue);
						if (response.status == 'ok') {
							$(form)[0].reset();
							$('#modal-enquiry-form-widget').addClass('small-modal');
							if($thisForm.parents('#modal-enquiry-form-widget').length){
								var thankyouDiv = $thisForm.parents('#modal-enquiry-form-widget').find(".form-enquiry-secondary__success");
								$thisForm.fadeOut(400,function(){
									// thankyouDiv.find("h4").html(response.message);
									thankyouDiv.find('h2').html(response.message);
									thankyouDiv.not('.dual_occupancy_warning').removeClass('inactive');
								});
							}
							else {
								if ($thisForm.find('.form-contact__content').length > 0) {
									$thisForm.parent().next('.form-contact__thank-you').children('h2').html(response.message);
									$thisForm.parent().next('.form-contact__thank-you').removeClass('hidden');
								}
								else {
									$thisForm.next('.form-booking__thank-you').children('h2').html(response.message);
									$thisForm.next('.form-booking__thank-you').removeClass('hidden');
									$('.form-tradie__thank-you').removeClass('hidden');
								}
							}

							dataLayer.push({
								formType: 'ctaForm',
								formId: $thisForm.attr('id'),
								event: 'formSubmissionSuccess'
							});
						}
						else {
							alert(response.message);
						}
					}
				});
			}
		});
	});

	$('.cta-signup').validate({
		errorClass: 'error',

		highlight: function(element, errorClass) {
			$(element).addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).removeClass('error');
		},

		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $(form).serialize(),
				$thisForm = $('#' + this.currentForm.id),
				$submitButton = $thisForm.find('input[type=submit]'),
				submitValue = $submitButton.val();
			$submitButton.prop('disabled', true).val('Submitting...');
			serializedForm += "&name=" + $thisForm.find('input[name=first_name]').val() + " " + $thisForm.find('input[name=last_name]').val();
			serializedForm += "&url=" + window.location;
			fadeLoader('in');
			$.ajax({
				type: 'POST',
				url: '/ajax/subscribe.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					fadeLoader('out');
					$submitButton.prop('disabled', false).val(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();

						$('.cta-signup').html('<h2 class="thanks">Thanks for subscribing</h2>');
						dataLayer.push({
							formType: 'cta-signup',
							formId: $thisForm.attr('id'),
							event: 'formSubmissionSuccess'
						});
					}
					else {
						alert(response.message);
					}
				}
			});
		}
	});

	$('.book-callback_form').not('.dvhealth').validate({
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).addClass('error');
			$(element).parents('.form-contact__field-container').addClass('error');
		},
		unhighlight: function(element, errorClass) {
			$(element).removeClass('error');
			$(element).parents('.form-contact__field-container').removeClass('error');
		},
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $(form).serialize(),
				$thisForm = $('#' + this.currentForm.id),
				$submitButton = $thisForm.find('input[type=submit]'),
				submitValue = $submitButton.val(),
				ajaxCall = $thisForm.attr('data-ajax-url');

			$submitButton.prop('disabled', true).val('Submitting...');
			$.ajax({
				type: 'POST',
				url: '/ajax/' + ajaxCall + '.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					fadeLoader('out');
					$submitButton.prop('disabled', false).val(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();
						$('#modal-enquiry-form-widget').addClass('small-modal');
						var thankyouDiv = $('.book_callback_thank_you');
						$thisForm.fadeOut(400,function(){

							thankyouDiv.fadeIn('slow');
						});

						dataLayer.push({
							formType: 'ctaForm',
							formId: $thisForm.attr('id'),
							event: 'formSubmissionSuccess'
						});
					}
					else if (response.status == 'covid') {
							$(form)[0].reset();

								$('#modal-enquiry-form-widget').addClass('small-modal');
								var thankyouDiv = $('.book_callback_thank_you_error');
								$thisForm.fadeOut(400,function(){

									thankyouDiv.fadeIn('slow');
								});

							dataLayer.push({
								formType: 'ctaForm',
								formId: $thisForm.attr('id'),
								event: 'formSubmissionSuccess'
							});
						}
					else {
						alert(response.message);
					}
				}
			});
		}
	});

	$('.dvhealth').validate({
		ignore: [],
  		rules: {
        symptoms: "required",
		exposed: 'required',
		coronavirus: "required",
		overseas: 'required',
		postcode: {required:true, digits:true, minlength:4,maxlength:4}
    },
  		messages: {
 		 symptoms: "This is a required field",
 		 exposed: "This is a required field",
 		 coronavirus: "This is a required field",
 		 overseas: "This is a required field",
 		 postcode: "This is a required field"

    	},
		errorClass: 'error',
		highlight: function(element, errorClass) {
			$(element).addClass('error');
			// console.log(element.type);
			if (element.type === 'radio') {
				 $(element).closest('.competition_radios').addClass('error');
				 if(element.name == 'symptoms'){
				 	$('.symptoms-error').addClass('error');
				 }
				 if(element.name == 'exposed'){
					 $('.exposed-error').addClass('error');
				 }
				 if(element.name == 'coronavirus'){
					 $('.coronavirus-error').addClass('error');
				 }
				 if(element.name == 'overseas'){
					 $('.overseas-error').addClass('error');
				 }


			} else {
				$(element).parents('.form-contact__field-container').addClass('error');
        	}


		},
		unhighlight: function(element, errorClass) {
			$(element).removeClass('error');

			if (element.type === 'radio') {

                $(element).closest('.competition_radios').removeClass('error');
				 if(element.name == 'symptoms'){
				 	$('.symptoms-error').removeClass('error');
				 }
				 if(element.name == 'exposed'){
					 $('.exposed-error').removeClass('error');
				 }
				 if(element.name == 'coronavirus'){
					 $('.coronavirus-error').removeClass('error');
				 }
				 if(element.name == 'overseas'){

					 $('.overseas-error').removeClass('error');
				 }

            }
            else
            { // This is the default behavior
               $(element).parents('.form-contact__field-container').removeClass('error');
            }


		},
		errorPlacement: function(error, element) { },
		submitHandler: function(form) {
			var serializedForm = $(form).serialize(),
				$thisForm = $('#' + this.currentForm.id),
				$submitButton = $thisForm.find('input[type=submit]'),
				submitValue = $submitButton.val(),
				ajaxCall = $thisForm.attr('data-ajax-url');

			$submitButton.prop('disabled', true).val('Submitting...');
			$.ajax({
				type: 'POST',
				url: '/ajax/' + ajaxCall + '.php',
				data: serializedForm,
				dataType: 'json',
				success: function(response) {
					fadeLoader('out');
					$submitButton.prop('disabled', false).val(submitValue);
					if (response.status == 'ok') {
						$(form)[0].reset();
						$('#modal-enquiry-form-widget').addClass('small-modal');
						var thankyouDiv = $('.book_callback_thank_you');
						$thisForm.fadeOut(400,function(){

							thankyouDiv.fadeIn('slow');
						});

						dataLayer.push({
							formType: 'ctaForm',
							formId: $thisForm.attr('id'),
							event: 'formSubmissionSuccess'
						});
					}
					else if (response.status == 'covid') {
							$(form)[0].reset();

								$('#modal-enquiry-form-widget').addClass('small-modal');
								var thankyouDiv = $('.book_callback_thank_you_error');
								$thisForm.fadeOut(400,function(){

									thankyouDiv.fadeIn('slow');
								});

							dataLayer.push({
								formType: 'ctaForm',
								formId: $thisForm.attr('id'),
								event: 'formSubmissionSuccess'
							});
						}

					else {
						alert(response.message);
					}
				}
			});
		}
	});

	$('.filters input[type=radio]').on('click', function() {
		dataLayer.push({
			formType: 'filter',
			formId: 'filter',
			event: 'searchPanelEvent',
			searchFeature: $(this).attr('id')
		});
	});
	$('.filters select').on('change', function() {
		dataLayer.push({
			formType: 'filter',
			formId: 'filter',
			event: 'searchPanelEvent',
			searchFeature: $(this).attr('name')
		});
	});
	$('.filters input[type=checkbox]').on('click', function() {
		if ($(this).is(':checked')) {
			dataLayer.push({
				formType: 'filter',
				formId: 'filter',
				event: 'searchPanelEvent',
				searchFeature: $(this).attr('id')
			});
		}
	});

	$('.house-and-land-landing-page .main-part, .house-and-land .main-inner, #homes-on-display, .display-for-sale_detail .main-inner, .display-for-sale .main-inner, .selected_hl_packages').on('click','.button__see-terms' ,function(e) {
		e.preventDefault();
		// get the page ID
		var pageID = $(this).parents('.property').attr('data-id');
		if (pageID.length > 0) {
			// get disclaimer text
			$.ajax({
				type: 'POST',
				url: '/ajax/get_terms.php',
				data: ({ page_id: pageID }),
				dataType: 'json',
				success: function(response) {
					if (response.status == 'ok') {
						$('#disclaimer-content').html(response.value);
						$('.product-disclaimer-modal').css("visibility","visible").fadeTo(500,1);
					}
					else {
						alert(response.message);
					}
				}
			});
		}
		return false;
	});

	$('#homes-on-display, .search-results .hl-search-result').on('click', '.button__see-terms', function(e) {
		e.preventDefault();
		var pageID = $(this).parents('.property').attr('data-id');
		if (pageID.length > 0) {
			$.ajax({
				type: 'POST',
				url: '/ajax/get_terms.php',
				data: ({
					page_id: pageID
				}),
				dataType: 'json',
				success: function(response) {
					if (response.status == 'ok') {
						$('#disclaimer-content').html(response.value);
						$('.disclaimer-modal').css("visibility","visible").fadeTo(500,1);
					} else {
						alert(response.message);
					}
				}
			});
		}
		return false;
	});

	$('.readybuilt-landing .main-part, .selected_rb_packages').on('click','.button__see-terms' ,function(e) {
		e.preventDefault();
		// get the page ID
		var pageID = $(this).parents('.property').attr('data-id');
		if (pageID.length > 0) {
			// get disclaimer text
			$.ajax({
				type: 'POST',
				url: '/ajax/rb_get_terms.php',
				data: ({ page_id: pageID }),
				dataType: 'json',
				success: function(response) {
					if (response.status == 'ok') {
						$('#disclaimer-content').html(response.value);
						$('.disclaimer-modal').css("visibility","visible").fadeTo(500,1);
					}
					else {
						alert(response.message);
					}
				}
			});
		}
		return false;
	});
	$('.display-for-sale .main-part').on('click','.button__see-terms' ,function(e) {
		e.preventDefault();
		// get the page ID
		var pageID = $(this).parents('.property').attr('data-id');
		if (pageID.length > 0) {
			// get disclaimer text
			$.ajax({
				type: 'POST',
				url: '/ajax/get_terms.php',
				data: ({ page_id: pageID }),
				dataType: 'json',
				success: function(response) {
					if (response.status == 'ok') {
						$('#disclaimer-content').html(response.value);
						$('.disclaimer-modal').css("visibility","visible").fadeTo(500,1);
					}
					else {
						alert(response.message);
					}
				}
			});
		}
		return false;
	});

	$('.readybuilt-filter__select').on('change', '#readybuilt-suburb, #readybuilt-estate', function (e) {
		var $select  = $(this),
			selectID = $(this).attr('id'),
			range    = $(this).attr('data-range-id'),
			isParent = $(this).attr('data-is-parent'),
			theData;

		if (selectID == 'readybuilt-suburb') {
			theData = { range: range, suburb: $select.val(), isParent: isParent };
		}
		else if (selectID == 'readybuilt-estate') {
			theData = { range: range, estate: $select.val(), isParent: isParent };
		}

		$.ajax({
			type: 'POST',
			url: '/ajax/rb_listing.php',
			data: theData,
			dataType: 'html',
			success: function(html) {
				$('.readybuilt-table__container').html(html);
				propertyItemSlider();
			}
		});
	});
	$('.readybuilt-filter__regions').on('change', 'input:radio', function (e) {
		var $radio   = $(this),
			radioID  = $(this).attr('id'),
			range    = $(this).attr('data-range-id'),
			isParent = $(this).attr('data-is-parent'),
			theData = { range: range, isParent: isParent, region: $radio.val() };

		$.ajax({
			type: 'POST',
			url: '/ajax/rb_listing.php',
			data: theData,
			dataType: 'html',
			success: function(html) {
				$('.readybuilt-table__container').html(html);
				propertyItemSlider();
			}
		});
	});
	$('.readybuilt-table__container, .readybuilt-listing, .readybuilt-grid, .selected_rb_packages, body.readybuilt-landing .houses-filter__list .ajax-content-here, #homes-on-display').on('click', 'a.readybuilt-table__enquire, a.readybuilt-listing__enquire, a.readybuilt-grid__enquire', function(e) {
		if ($(this).hasClass('readybuilt-grid__enquire') && $('body').hasClass('henley-sa')) {
			return true;
		}
		else {
			// set some stuff in the form
			var thisAddress = $(this).attr('data-address-enquiry');
			var thisAddressDisplay = $(this).attr('data-address-display');
			var thisSuburb = $(this).attr('data-suburb');
			var thisCollection = $(this).attr('data-collection');
			if (typeof thisSuburb != 'undefined') {

				$('#werribee_override').val('no');
				$('#non_werribee_fields').show();

			}
			else {
				if ($('#werribee_override').length > 0) $('#werribee_override').val('no');
				if ($('#non_werribee_fields').length > 0) $('#non_werribee_fields').show();
			}
			$('#address_of_enquiry').val(thisAddress);
			$('#collection_interested_in').val(thisCollection);

			$('#rb_modal_override_region_id').val('');
			$('#rb_modal_override_sales_centre_id').val('');
			$('#field-region').parents('.form-controls custom--select-tertiary').show();
			$('#field-region').parents('.form-enquiry-secondary__field-container').show();
			$('#field-sales-centre').parents('.form-controls custom--select-tertiary').show();
			$('#field-sales-centre').parents('.form-enquiry-secondary__field-container').show();
			if ($(this).hasClass('readybuilt-grid__enquire') && $('body').hasClass('readybuilt-landing')) {
				var thisRegion = $(this).attr('data-region-id'),
					thisSalesCentre = $(this).attr('data-sales-centre-id');
				if (typeof thisRegion != 'undefined' && typeof thisSalesCentre != 'undefined') {
					if (thisRegion.length > 0) {
						$('#rb_modal_override_region_id').val(thisRegion);
						$('#field-region').parents('.form-controls custom--select-tertiary').hide();
						$('#field-region').parents('.form-enquiry-secondary__field-container').hide();
					}
					if (thisSalesCentre.length > 0) {
						$('#rb_modal_override_sales_centre_id').val(thisSalesCentre);
						$('#field-sales-centre').parents('.form-controls custom--select-tertiary').hide();
						$('#field-sales-centre').parents('.form-enquiry-secondary__field-container').hide();
					}
				}
			}

			$('.form-enquiry-secondary__aside em').html(thisAddressDisplay);
			$('#modal-enquiry-form').modal();
			return false;
		}
	});
	propertyItemSlider();

	$('.selected_rb_packages').on('click', '.close_view-more', function() {
		$('.slide-down-preview.active').trigger('click');
		return false;
	});

	// readybuilt townhomes
	$('.section-townhomes__tab-anchors').on('click', 'a', function() {
		var selectedTab       = $(this).attr('data-tab'),
			$showTab          = $('#townhome-tab-' + selectedTab),
			$currentTabAnchor = $(this).parents('ul').children('.selected'),
			$currentTab       = $('.section-townhomes__tabs .section-townhomes__tab.selected');
		$currentTabAnchor.removeClass('selected');
		$currentTab.removeClass('selected');
		$(this).parent('li').addClass('selected');
		$showTab.addClass('selected');
		return false;
	});
	$('.section-townhomes-listings__list').on('click', 'a.expand-townhomes-listing', function() {
		var $thisAnchor   = $(this),
			toExpand      = $(this).attr('data-expand'),
			expandPageID  = toExpand.replace('listing-expand-', ''),
			$toExpand     = $('#' + toExpand),
			$floorplanDiv = $('#floorplan-' + expandPageID),
			$floorplanObj = $toExpand.find('.floorplan__svg'),
			$loaderPanel  = $floorplanDiv.children('.loader-panel');

		if ($toExpand.hasClass('expanded')) {
			// close
			$toExpand.removeClass('expanded');
			$thisAnchor.html('View');
		}
		else {
			// open
			$toExpand.addClass('expanded');
			$thisAnchor.html('Hide');
			if (!$floorplanObj.hasClass('loaded')) {
				fadeLoader('in', null, $loaderPanel);
				var svgID = '#' + $floorplanObj.attr('id'),
					svgPath = $floorplanObj.attr('data-image');
				$floorplanObj.attr('data', svgPath);
				$floorplanObj[0].addEventListener('load', function() {
					rb_townhome_svgPanZoom(svgID);
					fadeLoader('out', null, $loaderPanel)
					$floorplanObj.addClass('loaded');
				});
			}
		}
		return false;
	});

	if ($('body').hasClass('house-and-land-landing-page')) {
		if ($('#special-offers-section').length > 0) {
			$('#special-offers-section').slick();
		}
	}

	if($('body').hasClass('readybuilt_townhomes')){
		if ($('.slider-images-secondary').length > 0) {
			$('.slider-images-secondary').slick();
		}
	}


	if ($('body.new-homes_series').length > 0 ||
		$('body.house-and-land').length > 0 ||
		$('body.general').length > 0 ||
		$('body.display-centre-single').length > 0
	) {
		var slidesToShow = 3;
		if ($('.slick-slide').hasClass('col-md-3')) slidesToShow = 4;

		$('.homes-on-display .slick-container').slick({
			infinite: true,
			slidesToShow: slidesToShow,
			slidesToScroll: 1,
			dots: true,
			autoplay: false,
			lazyLoad: 'progressive',
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: slidesToShow,
						dots: false
					}
				},
				{
					breakpoint: 1025,
					settings: {
						slidesToShow: 3,
						dots: true
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 2,
						dots: true
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						dots: true
					}
				}
			]
		});
	}

	// loader
	if ($('.loader-panel').length > 0) {
		loaderInit();
	}

	// compare section
	$('.home-type__select').on('click', 'a', function() {
		var $homeType    = $(this),
			rootID       = $(this).attr('data-root-id'),
			homeType     = $(this).attr('data-home-type'),
			$leftSelect  = $(this).parents('.compare-box__up').find('.select-left'),
			$rightSelect = $(this).parents('.compare-box__up').find('.select-right'),
			colNum       = $(this).parents('.compare-col').attr('data-compare-column');

		$('#compare-content-' + colNum).slideUp(function() {
			$('#compare-content-' + colNum).html('');
			fadeLoader('in');
			$.ajax({
				type: 'POST',
				url: '/ajax/compare_dropdowns.php',
				data: ({ homeType: homeType, rootID: rootID, side: 'left' }),
				dataType: 'json',
				success: function(response) {
					fadeLoader('out');
					if (response.status == 'ok') {
						if (response.results.length > 0) {
							$leftSelect.children().remove();
							$leftSelect.append('<option value="">' + $homeType.attr('data-left-placeholder') + '</option>');

							for (i in response.results) {
								var thisResult = response.results[i];
								$leftSelect.append('<option value="' + thisResult.id + '">' + thisResult.name + '</option>');
							}
							$leftSelect.attr('data-home-type', homeType).parent().removeClass('custom--select-tertiary-inactive');

							$rightSelect.children().remove();
							$rightSelect.append('<option value="">' + $homeType.attr('data-right-placeholder') + '</option>');
							$rightSelect.attr('data-home-type', homeType).parent().addClass('custom--select-tertiary-inactive');
						}
						$homeType.parents('.home-type__select').find('a.active').removeClass('active');
						$homeType.addClass('active');
					}
					else {
						alert('Sorry, but there was an error while handling your request. ' + response.message);
					}
				}
			});
		});
		return false;
	});

	$('.compare-box__up').on('change', 'select.select-left', function() {
		var colNum       = $(this).parents('.compare-col').attr('data-compare-column'),
			homeType     = $(this).attr('data-home-type'),
			$leftSelect  = $(this),
			$rightSelect = $(this).parents('.row--seven').find('select.select-right'),
			$homeType    = $(this).parents('.compare-box__up').find('.home-type__select a.active'),
			rootID       = $homeType.attr('data-root-id');

		if (!$(this).parent().hasClass('custom--select-tertiary-inactive')) {
			// populate the right side
			fadeLoader('in');
			$.ajax({
				type: 'POST',
				url: '/ajax/compare_dropdowns.php',
				data: ({ homeType: homeType, rootID: rootID, side: 'right', selectedLeft: $(this).val() }),
				dataType: 'json',
				success: function(response) {
					fadeLoader('out');
					if (response.status == 'ok') {
						$rightSelect.attr('data-home-type', homeType).children().remove();
						$rightSelect.append('<option value="">' + $homeType.attr('data-right-placeholder') + '</option>');
						if (response.results.length > 0) {
							for (var i in response.results) {
								var thisResult = response.results[i];
								if (typeof thisResult.children != 'undefined') {
									if (thisResult.children.length > 0) {
										var optgroupString = '<optgroup label="' + thisResult.name + '">';
										for (var j in thisResult.children) {
											optgroupString += '<option value="' + thisResult.children[j].id + '">' + thisResult.children[j].name + '</option>';
										}
										optgroupString += ' </optgroup>';
										$rightSelect.append(optgroupString);
									}
								}
								else {
									$rightSelect.append('<option value="' + thisResult.id + '">' + thisResult.name + '</option>');
								}
							}
						}
						$rightSelect.parent().removeClass('custom--select-tertiary-inactive');
					}
					else {
						alert('Sorry, but there was an error while handling your request. ' + response.message);
					}
				}
			});
		}
		else return false;
	});
	$('.compare-box__up').on('change', 'select.select-right', function() {
		var $homeType   = $(this).parents('.compare-box__up').find('.home-type__select a.active'),
			rootID      = $homeType.attr('data-root-id'),
			$leftSelect = $(this).parents('.row--seven').find('select.select-left'),
			parentID    = $leftSelect.val(),
			colNumber   = $(this).parents('.compare-col').attr('data-compare-column');

		if (!$(this).parent().hasClass('custom--select-tertiary-inactive')) {
			$('#compare-content-' + colNumber).show();
			fadeLoader('in');
			$.ajax({
				type: 'POST',
				url: '/ajax/get_compare.php',
				data: ({ id: $(this).val(), leftID: parentID, rootID: rootID, colNum: colNumber }),
				dataType: 'html',
				success: function(html) {
					fadeLoader('out');
					$('#compare-content-' + colNumber).html(html);
					$('#compare-col_' + colNumber).find('.compare-col__close').addClass('on');

					setTimeout(function() {
						$(window).lazyLoadXT();
					}, 50);

					var svgContainer = document.getElementById('svg' + colNumber);
					if (typeof svgContainer != 'undefined' && svgContainer != null ) {
						svgContainer.onload = function() {
							var svgDoc = svgContainer.contentDocument;
							if (svgDoc != null) {
								if (typeof svgDoc == 'object') {
									var $groundLevel = $('g[id$="-ground-floor"]', svgDoc);
									$groundLevel.attr('display', 'block').attr('class', '');
									$groundLevel.children('g').each(function() {
										if ($(this).is('[id$="-ground-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
										else $(this).attr('display', 'none').attr('class', '');
									});

									var $firstLevel  = $('g[id$="-first-floor"]', svgDoc);
									if (typeof $firstLevel != 'undefined') {
										$firstLevel.attr('display', 'none').attr('class', '');
										$firstLevel.children('g').each(function() {
											if ($(this).is('[id$="-first-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
											else $(this).attr('display', 'none').attr('class', '');
										});
									}
								}
							}
						}
					}
					compareEvenHeights('.compare--boxes .compare-col');
				}
			});
		}
		else return false;
	});

	$('.compare--boxes').on('click', 'a.compare-col__close', function() {
		var $thisObj       = $(this),
			$thisObjParent = $thisObj.parent(),
			colNum         = $thisObjParent.attr('data-compare-column');

		fadeLoader('in');
		$.ajax({
			type: 'POST',
			url: '/ajax/compare_remove.php',
			data: ({ columnNumber: colNum }),
			dataType: 'json',
			success: function(response) {
				if (response.status == 'ok') {
					$thisObj.next().find('.home-type__select a.active').removeClass('active');

					var $leftSelect  = $thisObjParent.find('select.select-left'),
						$rightSelect = $thisObjParent.find('select.select-right')

					$leftSelect.attr('data-home-type', '').children().remove().append('<option value=\"\">Select a series</option>');
					$leftSelect.append('<option value=\"\">Select a series</option>');
					$rightSelect.attr('data-home-type', '').children().remove();
					$rightSelect.append('<option value=\"\">Select a design</option>');
					$('#compare-content-' + colNum).html('');
					$thisObj.removeClass('on');
				}
				else {
					alert(response.message);
				}
				fadeLoader('out');
			}
		});
		return false;
	});

	$('.close-compare').on('click', function() {
		$('.compare-tooltip, .close-compare').fadeOut();
		return false;
	});

	if($('body').hasClass('compare')) {
		$('.compare-col').each(function() {
			var $compareImgWrapper = $(this).find('.compare-img-wrapper');
			if ($compareImgWrapper.length > 0) {
				var svgID = $compareImgWrapper.children('object').attr('id');
				var svgContainer = document.getElementById(svgID);
				if (typeof svgContainer != 'undefined' && svgContainer != null ) {
					svgContainer.addEventListener('load', function() {
						var svgDoc = svgContainer.contentDocument;
						if (svgDoc != null) {
							if (typeof svgDoc == 'object') {
								// check to see if there's more than one level for this floor plan
								var $groundLevel = $('g[id$="-ground-floor"]', svgDoc);
								$groundLevel.attr('display', 'block').attr('class', '');
								$groundLevel.children('g').each(function() {
									if ($(this).is('[id$="-ground-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
									else $(this).attr('display', 'none').attr('class', '');
								});

								var $firstLevel  = $('g[id$="-first-floor"]', svgDoc);
								if (typeof $firstLevel != 'undefined') {
									$firstLevel.attr('display', 'none').attr('class', '');
									$firstLevel.children('g').each(function() {
										if ($(this).is('[id$="-first-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
										else $(this).attr('display', 'none').attr('class', '');
									});
								}
							}
						}
					}, false);
				}
			}
			compareEvenHeights('.compare--boxes .compare-col');
		});

		// refresh all SVGs again just to clean up some things
		var unintObjTimeout = setTimeout(function () {
			$('.compare-col').each(function() {
				var $uninitObj = $(this).find('object:not(.svg-loaded)');
				if ($uninitObj.length > 0) {
					var svgID = $uninitObj.attr('id');
					var svgContainer = document.getElementById(svgID);
					if (typeof svgContainer != 'undefined' && svgContainer != null ) {
						var svgDoc = svgContainer.contentDocument;
						if (svgDoc != null) {
							if (typeof svgDoc == 'object') {
								// check to see if there's more than one level for this floor plan
								var $groundLevel = $('g[id$="-ground-floor"]', svgDoc);
								$groundLevel.attr('display', 'block').attr('class', '');
								$groundLevel.children('g').each(function() {
									if ($(this).is('[id$="-ground-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
									else $(this).attr('display', 'none').attr('class', '');
								});

								var $firstLevel  = $('g[id$="-first-floor"]', svgDoc);
								if (typeof $firstLevel != 'undefined') {
									$firstLevel.attr('display', 'none').attr('class', '');
									$firstLevel.children('g').each(function() {
										if ($(this).is('[id$="-first-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
										else $(this).attr('display', 'none').attr('class', '');
									});
								}
							}
						}
					}
				}
			});
		}, 1000);

		$('.compare-col').on('click', 'a.compare-box__floorplan-level', function() {
			var selectedLevel      = $(this).attr('data-level'),
				$compareCol        = $(this).parents('.compare-col'),
				$compareImgWrapper = $compareCol.find('.compare-img-wrapper'),
				svgID              = $compareImgWrapper.find('object').attr('id'),
				svgContainer       = document.getElementById(svgID);

			if (!$(this).hasClass('disabled')) {
				if (typeof svgContainer != 'undefined' && svgContainer != null ) {
					var svgDoc = svgContainer.contentDocument;
					if (svgDoc != null) {
						if (typeof svgDoc == 'object') {
							var $groundLevel = $('g[id$="-ground-floor"]', svgDoc);
							$groundLevel.children('g').each(function() {
								if ($(this).is('[id$="-ground-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
								else $(this).attr('display', 'none').attr('class', '');
							});

							var $firstLevel  = $('g[id$="-first-floor"]', svgDoc);
							if (typeof $firstLevel != 'undefined') {
								$firstLevel.children('g').each(function() {
									if ($(this).is('[id$="-first-floor-base"]')) $(this).attr('display', 'block').attr('class', '');
									else $(this).attr('display', 'none').attr('class', '');
								});
							}

							if (selectedLevel == 'ground') {
								$groundLevel.attr('display', 'block').attr('class', '');
								$firstLevel.attr('display', 'none').attr('class', '');
							}
							else {
								$groundLevel.attr('display', 'none').attr('class', '');
								$firstLevel.attr('display', 'block').attr('class', '');
							}
						}
					}

					$compareCol.find('a.compare-box__floorplan-level').removeClass('active');
					$(this).addClass('active');
				}
			}
			return false;
		});

		$('.compare--boxes').on('click', 'a.expand-icon', function(e) {
			e.preventDefault();
			var $column        = $(this).parents('.compare-col'),
				columnNumber   = $column.attr('data-compare-column'),
				$floorplanData = $column.find('.floorplan-data'),
				$zoomModal     = $('.zoom-in-modal'),
				$zoomModalLeft = $zoomModal.find('.left.block');

			var placeholders = ['zoom-houseName', 'zoom-totalSq', 'zoom-totalMSq', 'zoom-lotWidth', 'zoom-lotDepth', 'zoom-bedrooms',
				'zoom-bathrooms', 'zoom-powderrooms', 'zoom-cars', 'zoom-livingareas', 'zoom-fullDimensions'];
			for (i in placeholders) {
				var placeholder = placeholders[i];
				if ($('#' + placeholder).length > 0) {
					var value = $floorplanData.find('#' + placeholder.replace('zoom-', 'data-' + columnNumber + '-')).html();
					if (typeof value != 'undefined') {
						if (value.length > 0) $('#' + placeholder).html(value).show();
						else $('#' + placeholder).hide();
					}
					else $('#' + placeholder).hide();
				}
			}

			$('body').addClass('no-scroll');

			if ($('#zoom-lotWidth').html().length == 0 && $('#zoom-lotDepth').html().length == 0) $('.zoom-lotDimensions').hide();
			else $('.zoom-lotDimensions').show();
			$('#zoomfloorplan').parent('.right.block').css('visibility', 'hidden');

			// place SVG in full screen container
			var svg = $('#svg' + columnNumber).attr('data');
			$('#zoom_svg').attr('onload', 'svgOnLoad("zoom_svg");').attr('data', svg);

			$('.zoom-in-modal .center .right.block').css('visibility', 'visible');
			$('.zoom-in-modal').css("visibility", "visible").fadeTo(500,1);
			return false;
		});
	}

	// grid elements mobile slider handling
	if ($('body.general .section--grid-elements').find('.mobile-slider').length > 0) {
		$('body.general .section--grid-elements .grid-elements').slick({
			dots: true,
			infinite: true,
			slidesToShow: 1,
			responsive: [
				{
					breakpoint: 5000,
					settings: 'unslick'
				}, {
					breakpoint: 767,
					settings: {
						dots: true
					}
				}
			]
		});
	}

	// grid element modal handling
	$('body.general .section--grid-elements').on('click', 'a.open-modal', function() {
		var href = $(this).attr('href'),
			$modalGridElement = $('#modal-grid-element'),
			$modalIframe = $modalGridElement.find('iframe');
		$modalIframe.attr('src', '');
		$modalIframe.attr('src', href);
		$modalGridElement.modal();
		return false;
	});
	$('#modal-grid-element').on('hidden.bs.modal', function() {
		var $modalIframe = $('#modal-grid-element iframe');
		$modalIframe.attr('src', '');
	});

	var appointmentDates = [];
	if ($('#d365o-appointment').length > 0 || $('#d365o-search').length > 0) {
		var foundAppointments;
		// booking an appointment from a display centre page
		$('#d365o-appointment, #d365o-search').each(function() {
			$(this).validate({
				errorClass: 'error',
				highlight: function(element, errorClass) {
					$(element).parents('.form-group').addClass('error');
				},
				unhighlight: function(element, errorClass) {
					$(element).parents('.form-group').removeClass('error');
				},
				errorPlacement: function(error, element) { },
				submitHandler: function(form) {
					var $form           = $('#' + form.id),
						serializedForm  = $form.serialize(),
						$submitButton   = $form.find('button[type=submit]'),
						submitValue     = $submitButton.html(),
						submittingValue = 'Submitting...';

					if (form.id == 'd365o-search') submittingValue = "Searching...";

					$submitButton.prop('disabled', true).html(submittingValue);
					$.ajax({
						type: 'POST',
						url: '/ajax/appointments.php',
						data: serializedForm,
						dataType: 'json',
						success: function(response) {
							$submitButton.prop('disabled', false).html(submitValue);
							if (response.status == 'ok') {
								$(form)[0].reset();

								if (form.id == 'd365o-appointment') {
									var $thankyouDiv = $('#d365o-appointment').next('.form-booking__thank-you');
									$thankyouDiv.find('h2').html(response.message);
									$thankyouDiv.removeClass('hidden');

									dataLayer.push({
				                        formType: 'displayAppointment',
				                        formId: 'bookAppointment',
				                        event: 'formSubmissionSuccess'
				                    });


								}
								else if (form.id == 'd365o-search') {
									var foundAppointment = response.result,
										$editForm        = $('#d365o-appointment-edit');

									// populate the form
									var appointmentDate = foundAppointment.AppointmentContract.Date.substr(0, 10),
										appointmentTime = foundAppointment.AppointmentContract.WorkerRecId + '-'
														  + foundAppointment.AppointmentContract.FromTime + '-'
														  + foundAppointment.AppointmentContract.ToTime;
									$editForm.find('#edit-inputFirstName').val(foundAppointment.OpportunityStagingContract.FirstName);
									$editForm.find('#edit-inputLastName').val(foundAppointment.OpportunityStagingContract.LastName);
									$editForm.find('#edit-inputEmail').val(foundAppointment.OpportunityStagingContract.Email);
									$editForm.find('#edit-inputPhone').val(foundAppointment.OpportunityStagingContract.MobilePhone);
									$editForm.find('#edit-inputEnquiry').val(foundAppointment.OpportunityStagingContract.Subject);
									$editForm.find('#edit-inputLocation').val(foundAppointment.OpportunityStagingContract.SalesUnit);
									$editForm.find('#edit-appointment_date').attr('data-appointment-date', appointmentDate);
									$editForm.find('#edit-appointment_time').attr('data-appointment-time', appointmentTime).attr('data-update', 1);
									$editForm.find('#edit-inputHowDidYouHear').val(foundAppointment.OpportunityStagingContract.WhereDidYouHear);
									$editForm.find('#edit-inputMessage').val(foundAppointment.OpportunityStagingContract.Comments);
									$editForm.find('#edit-inputLocation').trigger('change');
									$editForm.parent('.booking-list').addClass('active');
								}
							}
							else {
								alert(response.message);
							}
						}
					});
				}
			});
		});
		$('#edit-appointment-delete').on('click', function() {
			if (confirm('Are you sure you want to delete your appointment?')) {
				$('#edit-api-action').val(2);
				return true;
			}
			else return false;
		});
		$('#edit-appointment-update').on('click', function() {
			$('#edit-api-action').val(1);
			return true;
		});
		$('#d365o-appointment-edit').validate({
			errorClass: 'error',
			highlight: function(element, errorClass) {
				$(element).parents('.form-group').addClass('error');
			},
			unhighlight: function(element, errorClass) {
				$(element).parents('.form-group').removeClass('error');
			},
			errorPlacement: function(error, element) { },
			submitHandler: function(form) {
				var $form          = $('#' + form.id),
					serializedForm = $form.serialize(),
					$submitButton  = $(this.submitButton),
					submitValue    = $submitButton.html();
				$submitButton.prop('disabled', true).html('Submitting...');
				$.ajax({
					type: 'POST',
					url: '/ajax/appointments.php',
					data: serializedForm,
					dataType: 'json',
					success: function(response) {
						$submitButton.prop('disabled', false).html(submitValue);
						if (response.status == 'ok') {
							var $thankyouDiv = $('#d365o-appointment-edit').next('.form-booking__thank-you');
							$thankyouDiv.find('h2').html(response.message);
							$thankyouDiv.removeClass('hidden');
						}
						else {
							alert(response.message);
						}
					}
				});
			}
		});
		$('#edit-appointment_date').on('update', function() {
			var appointmentDate = $(this).attr('data-appointment-date');
			$(this).val(appointmentDate).removeAttr('data-appointment-date');
		});
		$('#edit-appointment_time').on('update', function() {
			var appointmentTime = $(this).attr('data-appointment-time');
			// add the appointment time at the start of the drodopwn list
			var fromTime    = appointmentTime.substr(appointmentTime.length - 11, 5),
				toTime      = appointmentTime.substr(appointmentTime.length - 5, 5),
				workerRecId = appointmentTime.substr(0, appointmentTime.length - 12);
			$(this).prepend('<option value="' + appointmentTime + '">' + fromTime + ' to ' + toTime + ' (' + workerRecId + ')</option>');
			$(this).val(appointmentTime).removeAttr('data-appointment-time');
		});
		$('.main-inner').on('change', '#inputLocation, #edit-inputLocation', function(trigger) {
			// get a list of appointment times
			var $thisObj         = $(this),
				selectedLocation = $thisObj.val(),
				$appointmentDate = $thisObj.parents('form').find('select[name=appointment_date]'),
				$appointmentTime = $thisObj.parents('form').find('select[name=appointment_time]');

			// clear out appointment_date and appointment_time dropdowns, reset to empty
			$appointmentDate.prop('disabled', true).parents('.custom-select').addClass('disabled');
			$appointmentTime.prop('disabled', true).parents('.custom-select').addClass('disabled');

			var $firstDateOption = $appointmentDate.children('option:first-child');
			$appointmentDate.empty().append($firstDateOption);
			var $firstTimeOption = $appointmentTime.children('option:first-child');
			$appointmentTime.empty().append($firstTimeOption);

			fadeLoader('in', null, $thisObj.parents('form').children('.loader-panel'));

			$.ajax({
				type: 'POST',
				url: '/ajax/appointments.php',
				data: {
					action: 'getAppointments',
					location: selectedLocation
				},
				dataType: 'json',
				success: function(response) {
					if (response.status == 'ok') {
						// reset appointmentDates array to empty
						appointmentDates = [];
						for (i in response.result) {
							var foundKey = foundTime = -1;

							thisResult = response.result[i];
							// needs moment.min.js, recompile gulp js:vendor to get this in the compiled js.
							thisDate = moment(thisResult.Date).format('YYYY-MM-DD');

							if (appointmentDates.length > 0) {
								// search the appointmentDates store
								for (j in appointmentDates) {
									var thisAppointmentDate = appointmentDates[j];
									if (thisAppointmentDate.date == thisDate) {
										foundKey = j;
									}
								}
								if (foundKey > -1) {
									// go through the times now
									for (k in appointmentDates[foundKey].times) {
										var thisAppointmentTime = appointmentDates[foundKey].times[k];
										if (
											thisAppointmentTime.fromTime == thisResult.FromTime &&
											thisAppointmentTime.toTime   == thisResult.ToTime
										) {
											foundTime = k;
										}
									}
									if (foundTime > -1) {
										appointmentDates[foundKey].times[foundTime].workerRecId.push(thisResult.WorkerRecId);
									}
									else {
										appointmentDates[foundKey].times.push({
											'workerRecId': [thisResult.WorkerRecId],
											'fromTime': thisResult.FromTime,
											'toTime': thisResult.ToTime
										});
									}
								}
							}

							if (foundKey == -1) {
								// just append it to the end of the array
								appointmentDates.push({
									'date': thisDate,
									'times': [{
										'workerRecId': [thisResult.WorkerRecId],
										'fromTime': thisResult.FromTime,
										'toTime': thisResult.ToTime
									}]
								});
							}
						}
						// populate the appointment_date dropdown list
						for (i in appointmentDates) {
							var thisAppointmentDate = appointmentDates[i];
							$appointmentDate.append('<option value="' + thisAppointmentDate.date + '">' + moment(thisAppointmentDate.date).format('DD/MM/YYYY') + '</option>');
						}
						$appointmentDate.prop('disabled', false).parents('.custom-select').removeClass('disabled');

						if ($thisObj.attr('id') == 'edit-inputLocation') {
							$('#edit-appointment_date').trigger('update').trigger('change');
							if ($('#edit-appointment_time').attr('data-update') == 1) {
								$('#edit-appointment_time').removeAttr('data-update').trigger('update');
							}
						}

						fadeLoader('out');
					}
					else {
						fadeLoader('out');
						alert(response.message);
					}
				}
			});
		});
		$('.main-inner').on('change', '#appointment_date, #edit-appointment_date', function() {
			var thisDate = $(this).val(),
				$appointmentTime = $(this).parents('.row').find('select[name=appointment_time]');

			// reset $appointmentTime
			$appointmentTime.prop('disabled', true).parents('.custom-select').addClass('disabled');
			var $firstTimeOption = $appointmentTime.children('option:first-child');
			$appointmentTime.empty().append($firstTimeOption);

			// locate the date node in appointmentDates array
			for (i in appointmentDates) {
				thisAppointmentDate = appointmentDates[i];
				if (thisAppointmentDate.date == thisDate) {
					// found the right node! now push all the times onto the #appointment_time dropdown
					for (j in thisAppointmentDate.times) {
						// pick a random WorkerRecID
						var workerRecId = thisAppointmentDate.times[j].workerRecId[Math.floor(Math.random() * thisAppointmentDate.times[j].workerRecId.length)];

						var thisVal = workerRecId + '-' + thisAppointmentDate.times[j].fromTime + '-' + thisAppointmentDate.times[j].toTime,
							thisLabel = thisAppointmentDate.times[j].fromTime + ' to ' + thisAppointmentDate.times[j].toTime;
						$appointmentTime.append('<option value=\"' + thisVal + '\">' + thisLabel + '</option>');
					}
				}
			}
			$appointmentTime.prop('disabled', false).parents('.custom-select').removeClass('disabled');
		});
	}

	// visualizer
	var isTV = false;
	if ($('body').hasClass('visualiser') || $('body').hasClass('visualiser_iframe')) isTV = true;

	$('.visualiser-modal').on('click', '.btn-danger', function() {
		$('#visualiser--terms').hide();
		return false;
	});
	$('.visualiser-modal').on('click', '.btn-success', function() {
		$('#visualiser--terms').hide().css('visibility', 'hidden');
		var categoryID = $('#visualiser--terms').data('visualizer-category');
		$('.visualizer__initial .visualizer__category-select').removeClass('showModal');
		$('.visualizer__category-select[data-visualizer-category=' + categoryID + ']').trigger('click');
		return false;
	});
	$('.visualizer__initial').on('click', '.visualizer__category-select', function() {
		if ($(this).hasClass('showModal')) {
			var categoryID = $(this).data('visualizer-category');
			$('#visualiser--terms').data('visualizer-category', categoryID).css("visibility","visible").css('opacity', 1).show();
		}
		else {
			//adding classes to hide previous
			$('.visualizer__initial').addClass('hidden');
			$('.visualizer__description').addClass('hidden');

			var categoryID = $(this).attr('data-visualizer-category');
			if (!$('#visualizer__category-' + categoryID).hasClass('open')) {
				$('.visualizer__initial a.on').removeClass('on');
				$('.visualizer__category.open').hide().removeClass('open');
				$('#visualizer__category-' + categoryID).show().addClass('open');
				$(this).addClass('on');
			}
		}
		if (isTV) {
			var hash = $(this).attr('data-visualizer-category');
			History.pushState(hash, null, '#' + hash);
			$('header .header__back', window.parent.document).show();
		}

		return false;
	});
	$('.visualizer__select-subcategory').on('click', '.visualizer__category-select', function() {
		var $thisObj      = $(this),
			subcategoryID = $thisObj.attr('data-visualizer-category');

			//adding classes to hide previous
			$thisObj.parents('.visualizer__select-subcategory').addClass('hidden');

		if (!$('#visualizer__category-' + subcategoryID).hasClass('open')) {
			$('.visualizer__select-subcategory a.on').removeClass('on');
			$('.visualizer__subcategory.open').hide().removeClass('open');
			$('#visualizer__category-' + subcategoryID).show().addClass('open');
			var $centerBlock = $thisObj.parents('.visualizer__category').children('.center-block');
			$centerBlock.children('h2').addClass('hidden');
			$centerBlock.children('h3').removeClass('hidden');
			$thisObj.addClass('on');
		}

		if (isTV) {
			var hash = $thisObj.attr('data-visualizer-category');
			History.pushState(hash, null, '#' + hash);
			$('header .header__back', window.parent.document).show();
		}

		return false;
	});

	$('.visualizer__buttons-back').click(function(){
		if (isTV) {
			History.back();
		}
		else {
			if($('.visualizer__subcategory.open').length){
				var $centerBlock = $('.visualizer__category.open').children('.center-block');
				$centerBlock.children('h2').removeClass('hidden');
				$centerBlock.children('h3').addClass('hidden');

				$('.visualizer__subcategory.open').hide().removeClass('open');
				$('.visualizer__select-subcategory.hidden').hide().removeClass("hidden").show();
			}
			else if($('.visualizer__category.open').length){
				$('.visualizer__category.open').hide().removeClass('open');
				$('.visualizer__description').show().removeClass('hidden');
				$('.visualizer__initial').hide().removeClass("hidden").show().addClass('on');
			}
		}
	});

	$('.visualizer__buttons-start-again').click(function(){
		if($('.visualizer__subcategory.open').length){
			$('.visualizer__category').hide().removeClass("open").removeClass("hidden")
			$('.visualizer__subcategory.open').hide().removeClass('open');
			$('.visualizer__select-subcategory.hidden').removeClass("hidden");
			$('.visualizer__initial').hide().removeClass("hidden").show().addClass('on');
		}else if($('.visualizer__category.open').length){
			$('.visualizer__category.open').hide().removeClass('open');
			$('.visualizer__initial').hide().removeClass("hidden").show().addClass('on');
		}
	});
	$('.visualizer__kiosk').on('click', function() {
		fadeLoader('in');
	});

	if (isTV) {
		fadeLoader('in');
		function visualiserHash(hash) {
			var currentHash = hash.replace('#', '');
			if (currentHash == "") {
				if($('.visualizer__subcategory.open').length){
					$('.visualizer__category').hide().removeClass("open").removeClass("hidden")
					$('.visualizer__subcategory.open').hide().removeClass('open');
					$('.visualizer__select-subcategory.hidden').removeClass("hidden");
					$('.visualizer__initial, .visualizer__description').hide().removeClass("hidden").show().addClass('on');
				}else if($('.visualizer__category.open').length){
					$('.visualizer__category.open').hide().removeClass('open');
					$('.visualizer__initial, .visualizer__description').hide().removeClass("hidden").show().addClass('on');
				}
				$('header .header__back', window.parent.document).show();
			}
			else {
				var anchor = 'a[data-visualizer-category="' + currentHash +'"]';
				// console.log('anchor: ' + anchor);
				// $(anchor).click();
				var $thisCategory = $('#visualizer__category-' + currentHash);
				$('.visualizer__initial, .visualizer__description').hide();
				$('.visualizer__category.open').removeClass('open').hide();
				$thisCategory.addClass('open').show();
				if ($thisCategory.hasClass('visualizer__subcategory')) {
					$thisCategory.parent('.visualizer__category').show().addClass('open').children('.visualizer__select-subcategory').addClass('hidden');
				}
				else {
					$thisCategory.children('.visualizer__select-subcategory').removeClass('hidden');
					$thisCategory.children('.visualizer__subcategory.open').removeClass('open').hide();
				}
				$('header .header__back', window.parent.document).show();
			}
			fadeLoader('out');
		}

		window.onhashchange = function() {
			visualiserHash(window.location.hash);
		};
		if (window.location.hash) {
			visualiserHash(window.location.hash);
		}
		else {
			fadeLoader('out');
		}
		if (window.location.hash == "") $('header .header__back', window.parent.document).show();
	}

	var postcodeXHR;
	var postcodeAjaxActive = false;

	function capturePostcode() {
		var enteredVal   = $(this).val(),
			$thisForm    = $(this).parents('form'),
			$resultsList = $thisForm.next('.postcode__search-results'),
			$buildYes    = $thisForm.parent().find('.postcode__yes-notice'),
			$buildNo     = $thisForm.parent().find('.postcode__no-notice'),
			$basePrice   = $thisForm.parent().find('.theBasePrice').attr('data-base-price');
			$basePriceSolar   = $thisForm.parent().find('.theBasePriceSolar').attr('data-base-price-solar');
			$basePriceSpend   = $thisForm.parent().find('.theBasePriceSpend').attr('data-base-price-spend');
			$baseSpendDiscount    = $thisForm.parent().find('.theBaseSpendDiscount').attr('data-spend-discount');

		if (postcodeAjaxActive === true) postcodeXHR.abort();
		postcodeAjaxActive = true;
		$buildYes.addClass('hidden');
		$buildNo.addClass('hidden');

		$.ajax({
			type: 'POST',
			url: '/ajax/where_we_build_postcode.php',
			data: {
				search: enteredVal,
				pageID: $thisForm.attr('data-page-id')
			},
			dataType: 'json',
			success: function(response) {
				// clear out old list
				$resultsList.children().remove();
				if (response.status == 'ok') {

					if (response.results.length > 0) {

						for (i in response.results) {
							thisResult = response.results[i];
							var liClass = "";
							if (thisResult.build_status == 'build') liClass = " class=\"build\"";

							var innerHTML = '<li' + liClass + '><a href=\"#\" class=\"postcode_search-result\" data-build-status=\"' +
								thisResult.build_status + '\" data-postcode=\"' + thisResult.postcode + '\" data-suburb=\"' + thisResult.suburb +
								'\" data-latitude=\"' + thisResult.latitude + '\" data-longitude=\"' + thisResult.longitude +
								'\" data-message=\"' + thisResult.message + '\" data-discount=\"' + thisResult.discount +
								'\" data-base-price-solar=\"' + $basePriceSolar + '\" data-base-price-spend=\"' + $basePriceSpend + '\" data-spend-discount=\"' + $baseSpendDiscount +
								'\" data-kdrb-discount=\"' + thisResult.kdrb_discount + '\" data-message-kdrb=\"' + thisResult.kdrb_message + '\" data-map-message=\"' + thisResult.map_message +
								'\" data-fhog-discount=\"' + thisResult.fhog + '\" data-base-price=\"' + $basePrice + '\">' +
								thisResult.suburb + ' (' + thisResult.postcode + ')</a></li>';
							$resultsList.append(innerHTML);
						}
						$resultsList.addClass('active');
					}
					else {
						$resultsList.removeClass('active');
						$('#postcode__no-build-notice').addClass('hidden');
					}
				}
				else {
					$resultsList.removeClass('active');
					$('#postcode__no-build-notice').addClass('hidden');
				}
				postcodeAjaxActive = false;
			}
		});
	}


	$('.postcode__suburb-search').keyup($.debounce(250, capturePostcode));
	$('.postcode__search-form').on('submit', function(e) {
		e.preventDefault(e);
	});
	$('.postcode__suburb-search').on('focus', function() {
		$(this).parents('form').removeClass('build');
	});

	function formatNumber(num) {
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
	}

	var priceFormatter = new Intl.NumberFormat('en', {
		style: 'decimal',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	});

	if ($('body').hasClass('new-homes_series') || ($('body').hasClass('readybuilt_lot'))) {
		$('.eligible-for__panel, .discount-qualify').on('change', 'input.eligible-for__check', function() {
			var pageID           = $(this).attr('data-page-id'),
				$thisParentRoot  = $(this).parents('.from-price'),
				$price           = $thisParentRoot.find('.eligible-for__price'),
				$priceLabel      = $('#eligible-for__after-grants-' + pageID),
				originalPrice    = $priceLabel.attr('data-original-price');

			// enquiry form clear out
			if ($('body').hasClass('readybuilt_lot')) {
				$('#modal-enquiry-form').find('.enquiry-form__eligible-for-fields').each(function() {
					$(this).val('');
				});
			}

			if ($thisParentRoot.find('.eligible-for__check:checked').length > 0) {
				var discounts = 0;
				$thisParentRoot.find('.eligible-for__check:checked').each(function() {
					discounts += parseInt($(this).val());
					var fieldName = $(this).attr('name');
					$('#enquiry-form__' + fieldName + '-' + pageID).val('Yes');
				});
				$priceLabel.html("$" + priceFormatter.format(originalPrice - discounts));
				$price.show();
			}
			else {
				$price.hide();
				$priceLabel.html("$" + priceFormatter.format(originalPrice));
			}
		});
	}

		$('.postcode__search-results').on('click', 'a', function() {

		var postcode               = $(this).attr('data-postcode'),
			suburb                 = $(this).attr('data-suburb'),
			buildStatus            = $(this).attr('data-build-status'),
			latitude               = $(this).attr('data-latitude'),
			longitude              = $(this).attr('data-longitude'),
			message                = $(this).attr('data-message'),
			mapMessage             = $(this).attr('data-map-message'),
			message_kdrb           = $(this).attr('data-message-kdrb'),
			baseprice              = parseInt($(this).attr('data-base-price')),
			basepricesolar         = parseInt($(this).attr('data-base-price-solar')),
			postcodeDiscount       = parseInt($(this).attr('data-discount')),
			spendDiscount          = parseInt($(this).attr('data-spend-discount')),
			fhogDiscount           = parseInt($(this).attr('data-fhog-discount')),
			kdrbDiscount           = parseInt($(this).attr('data-kdrb-discount')),
			basePriceSpend         = basepricesolar - spendDiscount,



			$thisFloorplan         = $(this).parents('.this-floorplan'),
			$searchBox             = $thisFloorplan.find('.postcode__search input.postcode__suburb-search'),
			$results               = $thisFloorplan.find('.postcode__search-results'),
			$buildYes              = $thisFloorplan.find('.postcode__yes-notice'),
			$buildYesSpend         = $thisFloorplan.find('.postcode__yes-notice-spend'),
			$buildYesKDRB          = $thisFloorplan.find('.postcode__yes-notice-kdrb'),
			$buildNo               = $thisFloorplan.find('.postcode__no-notice'),
			$buildSolar            = $thisFloorplan.find('.postcode__yes-notice-solar'),
			$buildSolarChecked     = $thisFloorplan.find('.solar_checkbox_yes'),
			$buildYesFinal         = $thisFloorplan.find('.postcode__yes-notice-final');
			$buildYesSpend         = $thisFloorplan.find('.spend_check_div');
			$spendItYourWay        = $thisFloorplan.find('#spendItYourWay');
			$spendItCheck          = $thisFloorplan.find('#spend_check');
			$solarCheck            = $thisFloorplan.find('#solar_check_yes');

			$totalNewHomeAdvantage = $thisFloorplan.find('.total-new-home-advantage'),
			$discountPrice         = $totalNewHomeAdvantage.find('h4.total-new-home-advantage__price'),
			$tripleAdvantagePanel  = $thisFloorplan.find('.triple-advantage-price--panel'),
			currentURL             = location.href;

		if ($buildNo.length == 0) {
			$buildNo = $('#postcode__no-build-notice');
		}

		$solarCheck  .prop("checked", true);
		$spendItCheck .prop("checked", true);

		$searchBox.val(suburb.toUpperCase() + ' (' + postcode + ')');

		$buildYesKDRB.addClass('hidden');
		$('.postcode__yes-notice-final').addClass('hidden');



		if (buildStatus == 'build') {
			if (!$('body').hasClass('new-homes_series')) {
				// Henley build in this suburb

				/*
				if (mapMessage != '') {
					var message = mapMessage;
				}
				*/


				var pinLat  = parseFloat(latitude),
					pinLong = parseFloat(longitude),
					myLatLng, marker;

				google.maps.event.clearListeners(map, 'zoom_changed');
				google.maps.event.clearListeners(map, 'resize');

				// clear old markers out
				if (typeof markers != 'undefined') {
					for (var i = 0; i < markers.length; i++) {
						markers[i].setMap(null);
					}
				}

				// move the map to this lat and long
				map.setCenter({ lat: pinLat, lng: pinLong });
				// drop a pin here
				var marker = new google.maps.Marker({
					position: {
						lat: pinLat,
						lng: pinLong
					},
					map: map,
					animation: google.maps.Animation.DROP,
					title: suburb
				});

				// sort out the infowindow for message, if available
				$('#map').find('.popup-container').remove();
				var contentString = "";
				if (typeof message != 'undefined') {
					if (message.length > 0) {
						contentString = '<p>' + message + '</p>';
						$('#popup-for-map').html(contentString).css('visibility', 'visible');

						Popup = createPopupClass();
						popup = new Popup(
							new google.maps.LatLng({ lat: pinLat, lng: pinLong }),
							document.getElementById('popup-for-map'));
						popup.setMap(map);
					}
					else {
						$('#popup-for-map').html('').css('visibility', 'hidden');
					}
				}
				else {
					$('#popup-for-map').html('').css('visibility', 'hidden');
				}

				markers.push(marker);

				$searchBox.parents('form').addClass('build');
				if ($('#map').next('#popup-for-map').length == 0) {
					$('#map').after('<div id="popup-for-map"></div>');
				}
				$buildYes.removeClass('hidden');
				$buildNo.addClass('hidden');
				$('.postcode__search-results').removeClass('active');
			}
			else {
				var $childPageSelector = $('#floorplan-view .section-design__body .design__select a.active'),
					childPageID = $childPageSelector.attr('data-page-id');

				var $priceMessage;
				console.log('SpendDisc ' + spendDiscount);

				$spendItYourWay.html('$' + priceFormatter.format(spendDiscount));

				$buildSolar.removeClass('hidden');
				$buildYes.removeClass('hidden');
				$buildYesSpend.removeClass('hidden');
				$('.listPrice').removeClass('hidden');
				$('.triple-advantage-price--panel').removeClass('hidden');

				$discountedPriceSolar = basepricesolar - postcodeDiscount;
				$('.change_on_series_home_click.active #solar_check_yes').attr('data-discounted-price',$discountedPriceSolar);
				$('.change_on_series_home_click.active #solar_check_yes').attr('data-discount',postcodeDiscount);
				$('.change_on_series_home_click.active #solar_check_yes').attr('data-discount-spend',spendDiscount);

				$buildSolarChecked.attr('checked','checked');

				$discountedPriceNoSolar = baseprice - postcodeDiscount;
				$('.change_on_series_home_click.active #solar_check_no').attr('data-discounted-price',$discountedPriceNoSolar);
				$('.change_on_series_home_click.active #solar_check_no').attr('data-discount',postcodeDiscount);
				$('.change_on_series_home_click.active #solar_check_no').attr('data-discount-spend',spendDiscount);
				$buildYes.children('p').html(message + '<br>');


				if(postcodeDiscount != 0){
						$priceMessage = (basepricesolar - postcodeDiscount);
						$priceMessageSpend = (basepricesolar - (postcodeDiscount + spendDiscount ));
						$('.listPrice').children('p').html('Your price from $' + priceFormatter.format($priceMessage));
						$('.listPriceSpend').children('p').html('Popular build zone <br>discounted price <br>from $' + priceFormatter.format($priceMessageSpend));


					} else {
						$priceMessage = basepricesolar;
						$priceMessageSpend = basepricesolar - spendDiscount;
						$('.listPrice').children('p').html('List price from $' + priceFormatter.format($priceMessage));
						$('.listPriceSpend').children('p').html('Popular build zone <br>discounted price <br>from $' + priceFormatter.format($priceMessageSpend));
					}

				if(message_kdrb !=''){

					/*

					$buildYes.children('p').html('Yes we build in <b>' + suburb + '</b> <br>Is your home a <br>');
					$buildYesKDRB.removeClass('hidden');
					$tripleAdvantagePanel.addClass('hidden');
					// prepopulate hidden fields in enquiry modal
					$('#triple_advantage_suburb').val(suburb);
					$('#triple_advantage_fhog').val('Yes');
					$('#triple_advantage_hbg').val('Yes');

					// populate radion buttons

					$discountedPriceNB = baseprice - postcodeDiscount;
					$('.change_on_series_home_click.active #kdrb_check_nb').attr('data-message',message);
					$('.change_on_series_home_click.active #kdrb_check_nb').attr('data-discounted-price',$discountedPriceNB);
					$('.change_on_series_home_click.active #kdrb_check_nb').attr('data-discount',postcodeDiscount);

					$discountedPriceKDRB = baseprice - kdrbDiscount;
					$('.change_on_series_home_click.active #kdrb_check_kdrb').attr('data-message',message_kdrb);
					$('.change_on_series_home_click.active #kdrb_check_kdrb').attr('data-discounted-price',$discountedPriceKDRB);
					$('.change_on_series_home_click.active #kdrb_check_kdrb').attr('data-discount',kdrbDiscount);

					$buildYes.removeClass('hidden');
					$buildNo.addClass('hidden');
					//$('.triple-advantage-price--panel').removeClass('hidden');
					//$('.listPrice').removeClass('hidden');
					if(postcodeDiscount != 0){
						$priceMessage = (baseprice - postcodeDiscount);
						$('.listPrice').children('p').html('Your Price From $' + priceFormatter.format($priceMessage));

					} else {
						$priceMessage = baseprice;
						$('.listPrice').children('p').html('List Price From $' + priceFormatter.format($priceMessage));
					}
					*/

				} else {
					//$buildYes.addClass('hidden');
					$buildYes.children('p').html(message);
					// var $buildZoneDiscount = $tripleAdvantagePanel.find('.triple-advantage-price--build-zone-discount');
					// $buildZoneDiscount.html("$" + priceFormatter.format(postcodeDiscount)).attr('data-build-zone-discount', postcodeDiscount);

					// var $fhogDiscount = $tripleAdvantagePanel.find('.triple-advantage-price--fhog-discount');
					// $fhogDiscount.html("$" + priceFormatter.format(fhogDiscount)).attr('data-fhog-discount', fhogDiscount);


					// recalculateTripleAdvantageTotals($tripleAdvantagePanel);

					// prepopulate hidden fields in enquiry modal
					$('#triple_advantage_suburb').val(suburb);
					$('#triple_advantage_fhog').val('Yes');
					$('#triple_advantage_hbg').val('Yes');

					//$('#solar_check_yes').trigger('click');

					//$buildNo.addClass('hidden');
					//$buildYes.removeClass('hidden');
					//$('.triple-advantage-price--panel').removeClass('hidden');
					//$('.listPrice').removeClass('hidden');


					if(postcodeDiscount != 0){
						$priceMessage = (basepricesolar - postcodeDiscount);
						$priceMessageSpend = (basepricesolar - (postcodeDiscount + spendDiscount));
						$('.listPrice').children('p').html('Your price from $' + priceFormatter.format($priceMessage));
						$('.listPriceSpend').children('p').html('Popular build zone <br>discounted price <br>from $' + priceFormatter.format($priceMessageSpend));

					} else {
						$priceMessage = basepricesolar;
						$priceMessageSpend = (basepricesolar - spendDiscount);
						$('.listPrice').children('p').html('List price from $' + priceFormatter.format($priceMessage));
						$('.listPriceSpend').children('p').html('Popular build zone <br>discounted price <br>from $' + priceFormatter.format($priceMessageSpend));
					}

				}
			}




			// log the postcode selection click
			$.ajax({
				type: 'POST',
				url: '/ajax/postcode_search_log.php',
				data: ({
					postcode: postcode,
					suburb: suburb,
					page_url: currentURL,
					build: 'yes'
				}),
				dataType: 'json',
				success: function(response) {

				}
			});
		}
		else {
			// Henley don't build in this suburb
			if (!$('body').hasClass('new-homes_series')) {
				$searchBox.parents('form').removeClass('build');
				$('#popup-for-map').css('visibility', 'hidden');
				$('#map').find('.popup-container').remove();
				$buildYes.addClass('hidden');
				$buildNo.removeClass('hidden');
			}
			else {
				if (message != 'null' && message.length > 0) $buildYes.children('p').html(message);
				else $buildYes.children('p').html('Unfortunately, we do not currently build in this suburb.');
				$buildYes.removeClass('hidden');
				$totalNewHomeAdvantage.hide();
				$('.listPrice').addClass('hidden');
				$discountPrice.html('');
				$('#triple_advantage_suburb').val('');
				$('#triple_advantage_fhog').val('');
				$('#triple_advantage_hbg').val('');
			}

			// log the postcode selection click
			$.ajax({
				type: 'POST',
				url: '/ajax/postcode_search_log.php',
				data: ({
					postcode: postcode,
					suburb: suburb,
					page_url: currentURL,
					build: 'no'
				}),
				dataType: 'json',
				success: function(response) {

				}
			});
		}

		// close the results and clear them out
		$results.removeClass('active');
		$results.children().remove();
		return false;
	});

	$('.secondary-kdrb-check').on('click', function() {

		var message                = $(this).attr('data-message'),
			discountedPrice        = $(this).attr('data-discounted-price'),
			theDiscount        	   = $(this).attr('data-discount'),
			$thisFloorplan         = $(this).parents('.this-floorplan'),
			$results               = $thisFloorplan.find('.postcode__search-results'),
			$buildYesFinal         = $thisFloorplan.find('.postcode__yes-notice-final');

			$buildYesFinal.removeClass('hidden');
			$buildYesFinal.children('p').html(message);

			$('.triple-advantage-price--panel').removeClass('hidden');
			$('.listPrice').removeClass('hidden');

			$priceMessage = discountedPrice;

			if(theDiscount > 0){
				$('.listPrice').children('p').html('Your Price From $' + priceFormatter.format($priceMessage));

			} else {
				$('.listPrice').children('p').html('List Price From $' + priceFormatter.format($priceMessage));
			}


		// close the results and clear them out
		$results.removeClass('active');
		$results.children().remove();
		//return false;
	});

	$('.solar-check').on('click', function() {


		var discountedPrice        = $(this).attr('data-discounted-price'),
			theDiscount        	   = $(this).attr('data-discount'),
			theDiscountSpend       = $(this).attr('data-discount-spend'),
			$thisFloorplan         = $(this).parents('.this-floorplan'),
			$results               = $thisFloorplan.find('.postcode__search-results'),
			$buildYesFinal         = $thisFloorplan.find('.postcode__yes-notice-final'),
			$buildYes              = $thisFloorplan.find('.postcode__yes-notice');
			$buildYesSpend         = $thisFloorplan.find('.postcode__yes-notice-spend');

			$buildYesFinal.removeClass('hidden');
			$buildYes.removeClass('hidden');
			//$buildYesFinal.children('p').html(message);

			$('.triple-advantage-price--panel').removeClass('hidden');
			$('.listPrice').removeClass('hidden');

			$priceMessage = discountedPrice;
			$priceMessageSpend = discountedPrice - theDiscountSpend;

			if(theDiscount > 0){
				$('.listPrice').children('p').html('Your price from $' + priceFormatter.format($priceMessage));
				$('.listPriceSpend').children('p').html('Popular build zone <br>discounted price <br>from $' + priceFormatter.format($priceMessageSpend));

			} else {
				$('.listPrice').children('p').html('List price from $' + priceFormatter.format($priceMessage));
				$('.listPriceSpend').children('p').html('Popular build zone <br>discounted price <br>from $' + priceFormatter.format($priceMessageSpend));
			}


		// close the results and clear them out
		$results.removeClass('active');
		$results.children().remove();
		//return false;
	});



	$('.triple-advantage-price--panel').on('change', 'input[type=radio]', function() {
		var $thisBreakdown = $(this).parents('.triple-advantage-price--breakdown'),
			$tripleAdvantagePanel = $thisBreakdown.parents('.triple-advantage-price--panel');

		if ($(this).hasClass('triple-advantage-price--fhog')) {
			if ($(this).val() == 'yes') {
				$thisBreakdown.find('.triple-advantage-price--fhog-discount').removeClass('hidden');
				$('#triple_advantage_fhog').val('Yes');
			}
			else {
				$thisBreakdown.find('.triple-advantage-price--fhog-discount').addClass('hidden');
				$('#triple_advantage_fhog').val('No');
			}
		}
		if ($(this).hasClass('triple-advantage-price--hbg')) {
			if ($(this).val() == 'yes') {
				$thisBreakdown.find('.triple-advantage-price--hbg-discount').removeClass('hidden');
				$('#triple_advantage_hbg').val('Yes');
			}
			else {
				$thisBreakdown.find('.triple-advantage-price--hbg-discount').addClass('hidden');
				$('#triple_advantage_hbg').val('No');
			}
		}
		recalculateTripleAdvantageTotals($tripleAdvantagePanel);
	});

	function recalculateTripleAdvantageTotals($tripleAdvantagePanel) {
		var buildZoneDiscount       = $tripleAdvantagePanel.find('.triple-advantage-price--build-zone-discount').attr('data-build-zone-discount'),
			mortgagePayments        = $tripleAdvantagePanel.find('.triple-advantage-price--half-mortgage').attr('data-mortgage-payments'),
			henleyGrantFreeUpgrades = $tripleAdvantagePanel.find('.triple-advantage-price--free-upgrades').attr('data-free-upgrades'),
			fhogDiscount            = $tripleAdvantagePanel.find('.triple-advantage-price--fhog-discount').attr('data-fhog-discount'),
			hbgDiscount             = $tripleAdvantagePanel.find('.triple-advantage-price--hbg-discount').attr('data-hbg-price'),
			$discountTotal          = $tripleAdvantagePanel.find('.triple-advantage-price--item--total'),
			$fromPrice              = $tripleAdvantagePanel.find('.triple-advantage-price--grand-total'),
			designPrice             = $fromPrice.attr('data-current-price');

		if ($tripleAdvantagePanel.find('.triple-advantage-price--free-upgrades').length == 0) henleyGrantFreeUpgrades = 0;

		buildZoneDiscount = parseInt(buildZoneDiscount);
		mortgagePayments = parseInt(mortgagePayments);
		henleyGrantFreeUpgrades = parseInt(henleyGrantFreeUpgrades);
		fhogDiscount = parseInt(fhogDiscount);
		hbgDiscount = parseInt(hbgDiscount);

		var totalDiscount = buildZoneDiscount + mortgagePayments + henleyGrantFreeUpgrades;
		if ($tripleAdvantagePanel.find('.triple-advantage-price--fhog-yes').is(':checked')) totalDiscount += fhogDiscount;
		if ($tripleAdvantagePanel.find('.triple-advantage-price--hbg-yes').is(':checked')) totalDiscount += hbgDiscount;
		$discountTotal.html("$" + priceFormatter.format(totalDiscount));
		var grandTotal = parseInt(designPrice) - parseInt(totalDiscount);
		$fromPrice.find('span').html("$" + priceFormatter.format(grandTotal));
	}

	function createPopupClass() {
		function Popup(position, content) {
			this.position = position;
			content.classList.add('popup-bubble');
			var bubbleAnchor = document.createElement('div');
			bubbleAnchor.classList.add('popup-bubble-anchor');
			bubbleAnchor.appendChild(content);
			this.containerDiv = document.createElement('div');
			this.containerDiv.classList.add('popup-container');
			this.containerDiv.appendChild(bubbleAnchor);
			google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
		}
		Popup.prototype = Object.create(google.maps.OverlayView.prototype);
		Popup.prototype.onAdd = function() {
			this.getPanes().floatPane.appendChild(this.containerDiv);
		};
		Popup.prototype.onRemove = function() {
			if (this.containerDiv.parentElement) {
				this.containerDiv.parentElement.removeChild(this.containerDiv);
			}
		};
		Popup.prototype.draw = function() {
			var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
			var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
					'block' : 'none';
			if (display === 'block') {
				this.containerDiv.style.left = divPosition.x + 'px';
				this.containerDiv.style.top  = divPosition.y + 'px';
			}
			if (this.containerDiv.style.display !== display) {
				this.containerDiv.style.display = display;
			}
		}
		return Popup;
	}
});

function compareEvenHeights(columns) {
	var divs = ['.compare-box__main', '.compare-box__dimensions', '.compare-box__bottom'],
		$columns = $(columns);
	if ($columns.length > 0) {
		for (i in divs) {
			var thisDiv = divs[i];
			var maxHeight = 0;
			$columns.each(function() {
				var $thisColumn = $(this);
				if ($thisColumn.find(thisDiv).length > 0) {
					var thisHeight = $thisColumn.find(thisDiv).outerHeight();
					if (thisHeight > maxHeight) maxHeight = thisHeight;
				}
			});
			if (maxHeight > 0) {
				$columns.find(thisDiv).each(function() {
					$(this).css('min-height', maxHeight + 'px' );
				});
			}
		}
	}
}

function loaderInit() {
	var $loaderPanel     = $('.loader-panel'),
		$loader          = $loaderPanel.children('.loader');
		loaderTop        = $loaderPanel.offset().top,
		halfLoaderHeight = Math.floor($loader.outerHeight() / 2),
		scrollCutoff     = Math.floor(loaderTop / 2) + halfLoaderHeight,
		bottomCutoff     = loaderTop + $loaderPanel.outerHeight(),
		posPadding       = 55,
		windowHeight     = $(window).height();

	var initialCSS = {
		position: 'absolute',
		top: (Math.floor(scrollCutoff / 2) - posPadding) + 'px',
		bottom: 'auto'
	};
	if ($('body').hasClass('search') || $('body').hasClass('contact') || $('body').hasClass('visualiser_iframe')) {
		initialCSS = {
			position: 'fixed',
			top: '50%',
			bottom: 'auto', marginTop: "-" + halfLoaderHeight + 'px'
		};
	}
	if ($('body').hasClass('compare')) {
		posPadding = 0; // -1 * loaderTop;
		var initialCSS = {
			position: 'absolute',
			top: (Math.floor(scrollCutoff / 2) - posPadding) + 'px',
			bottom: 'auto'
		};
	}
	if ($('body').hasClass('general') || $('body').hasClass('new-homes_series') || $('body').hasClass('display-centre-single')) {
		initialCSS = {
			position: 'absolute',
			top: '50%'
		}
	}

	$loader.css(initialCSS);

	if (!$('body').hasClass('general') && !$('body').hasClass('new-homes_series') && !$('body').hasClass('display-centre-single')) {
		$(window).on('scroll', function() {
			var scrollPos    = $(window).scrollTop(),
				windowHeight = $(window).height(),
				bottomMargin = Math.floor(windowHeight / 2);

			if (scrollPos > scrollCutoff) {
				if (loaderTop >= scrollCutoff) {
					bottomPadding = (Math.abs(parseInt($loader.css('margin-top'))) / 2) + $loader.outerHeight();
					if ($('body').hasClass('search')) bottomPadding += (Math.abs(parseInt($loader.css('margin-top'))));

					if (scrollPos > (bottomCutoff - windowHeight + bottomPadding)) {
						$loader.css({ position: 'absolute', top: 'auto', bottom: (Math.floor(scrollCutoff / 2) + bottomPadding) + 'px' });
					}
					else {
						$loader.css({ position: 'fixed', top: '50%', bottom: 'auto' });
					}
				}
				else $loader.css(initialCSS);
			}
			else $loader.css(initialCSS);
		});
		$(window).on('resize', function() {
			windowHeight = $(window).height();
		});
	}
}

function mobileView() {
	return $(window).outerWidth() < 768;
}
function eqHeight($elem){
	if (!$elem.length) return;

	if (!mobileView()) {
		var maxHeight = 0;
		$elem.outerHeight('auto');
		$elem.each(function(){
			var elemHeight = $(this).outerHeight();
			if ( maxHeight > elemHeight ) maxHeight = maxHeight;
			else maxHeight = elemHeight;
		});
		$elem.outerHeight(maxHeight);
	} else {
		setTimeout(function() {
			$elem.each(function(){
				$elem.outerHeight('auto');
			});
		}, 250);
	}
}

function fadeLoader(direction, callback, loader) {
	var $loader;
	if (typeof loader == 'object') $loader = loader;
	else if (typeof loader == 'string') $loader = $(loader);
	else $loader = $('.loader-panel');

	if (direction == 'in') {
		$loader.removeClass('stopped').show().css({ zIndex: 1001, visibility: 'visible' });
		loaderInit();
		$(window).trigger('scroll');
		$loader.fadeTo(400, 1);
	}
	else {
		$loader.fadeTo(400, 0, function() {
			$(this).delay(500).addClass('stopped').css({ zIndex: -1, visibility: 'hidden' }).hide();
		});
	}
	setTimeout(function() {
		Waypoint.refreshAll();
	}, 500);
	if (callback && typeof(callback) == 'function') callback();
}

function ini_svgPanZoom(selector) {
	var panEnabled = true;
	if ($(selector).parent().is(':hidden') == false) {
		if ($('html').width() < 768 || $('html').hasClass('tablet')) panEnabled = false;

		var panZoom = svgPanZoom(selector, {
			zoomEnabled: panEnabled,
			panEnabled: panEnabled,
			//customEventsHandler: pinchZoomEventsHandler,
			controlIconsEnabled: false,
			mouseWheelZoomEnabled: false,
			preventMouseEventsDefault: false,
			dblClickZoomEnabled: false,
			fit: 1,
			center: 1,
			// maxZoom: $('#svg1').data('max-zoom'),
			beforePan: function(oldPan, newPan) {
				var gutterWidth = 300,
					gutterHeight = 300;
				// Computed variables
				var sizes = this.getSizes(),
					leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth,
					rightLimit = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom),
					topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight,
					bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom);
				customPan = {};
				customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
				customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));
				return customPan;
			}
		});
		if ($('html').hasClass('mobile')) {
			if ($(selector).attr('data-storeys') != '1') {
				panZoom.updateBBox();
				panZoom.reset();
				panZoom.fit();
			}
			else {
				panZoom.reset();
			}
		}
		else {
			panZoom.reset();
		}
		if (selector == '#svg30774' || selector == '#svg33043') {
			panZoom.zoomBy(0.9);
		}
		if (selector == '#svg31649' || selector == '#svg31643' || selector =='#svg31647' || selector =='#svg32729' || selector == '#svg32731') {
			panZoom.zoomBy(0.8);
		}




		$('.design .ico-zoom-in').off('click');
		$('.design .ico-zoom-in').click(function(e){
			e.preventDefault();
			panZoom.zoomIn();
		});
		$('.design .ico-zoom-out').off('click');
		$('.design .ico-zoom-out').click(function(e){
			e.preventDefault();
			panZoom.zoomOut();
		});

		$('.design__select-design a').click(function(){
			//panZoom.setMaxZoom($(this).data('max-zoom'));
		});
		$('.design__image').css('visibility', 'visible');
	}
}

function rb_townhome_svgPanZoom(selector) {
	var panEnabled = true;
	if ($(selector).parent().is(':hidden') == false) {
		if ($('html').width() < 768 || $('html').hasClass('tablet')) panEnabled = false;

		var panZoom = svgPanZoom(selector, {
			zoomEnabled: panEnabled,
			panEnabled: panEnabled,
			//customEventsHandler: pinchZoomEventsHandler,
			controlIconsEnabled: false,
			mouseWheelZoomEnabled: false,
			preventMouseEventsDefault: false,
			dblClickZoomEnabled: false,
			fit: 1,
			center: 1,
			beforePan: function(oldPan, newPan) {
				var gutterWidth = 300,
					gutterHeight = 300;
				// Computed variables
				var sizes       = this.getSizes(),
					leftLimit   = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth,
					rightLimit  = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom),
					topLimit    = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight,
					bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom);
				customPan   = {};
				customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
				customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));
				return customPan;
			}
		});
		if ($('html').hasClass('mobile')) {
			if ($(selector).attr('data-storeys') != '1') {
				panZoom.updateBBox();
				panZoom.reset();
				panZoom.fit();
			}
			else {
				panZoom.reset();
			}
		}
		else {
			panZoom.reset();
		}

		/*
		$('.design .ico-zoom-in').off('click');
		$('.design .ico-zoom-in').click(function(e){
			e.preventDefault();
			panZoom.zoomIn();
		});
		$('.design .ico-zoom-out').off('click');
		$('.design .ico-zoom-out').click(function(e){
			e.preventDefault();
			panZoom.zoomOut();
		});

		$('.design__select-design a').click(function(){
			//panZoom.setMaxZoom($(this).data('max-zoom'));
		});
		$('.design__image').css('visibility', 'visible');
		*/
	}
}

function rb_zoom_svgPanZoom(svgID) {
	var panEnabled = true;
	if ($('html').width() < 768 || $('html').hasClass('ipad')) panEnabled = false;
	var panZoom2 = svgPanZoom('#' + svgID, {
		zoomEnabled: panEnabled,
		panEnabled: panEnabled,
		customEventsHandler: pinchZoomEventsHandler,
		controlIconsEnabled: false,
		mouseWheelZoomEnabled: false,
		dblClickZoomEnabled: false,
		beforePan: function(oldPan, newPan) {
			if ($('html').width() < 768) {
				var gutterWidth = 100,
					gutterHeight = 100;
			}
			else {
				var gutterWidth = 600,
					gutterHeight = 600;
			}
			// Computed variables
			var sizes       = this.getSizes(),
				leftLimit   = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth,
				rightLimit  = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom),
				topLimit    = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight,
				bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom);
			customPan   = {};
			customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
			customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));
			return customPan;
		}
	});
	panZoom2.reset();

	setTimeout(function() {
		$('#' + svgID).attr('data-reset', 'reset');
		// check to see if the SVG fits properly
		var svgContainer = document.getElementById(svgID);
		if (typeof svgContainer != 'undefined' && svgContainer != null) {
			var svgDoc         = svgContainer.contentDocument,
				$viewport      = $('g.svg-pan-zoom_viewport', svgDoc);
			if ($viewport.length == 0) $viewport = $('g[id^="viewport-"]', svgDoc);

			var viewportHeight = $viewport[0].getBoundingClientRect().height,
				viewportWidth  = $viewport[0].getBoundingClientRect().width,
				zoomHeight     = $('#' + svgID).outerHeight();
				zoomWidth      = $('#' + svgID).outerWidth();
			if (viewportHeight > zoomHeight || viewportWidth > zoomWidth) {
				$('#' + svgID).attr('data-reset', 'fit');
				panZoom2.updateBBox();
				panZoom2.fit();
			}
		}
	}, 1000);

	$('body.readybuilt .zoom-in-modal .ico-zoom-in').off('click');
	$('body.readybuilt .zoom-in-modal .ico-zoom-in').click(function(e){
		e.preventDefault();
		panZoom2.zoomIn();
	});
	$('body.readybuilt .zoom-in-modal .ico-zoom-out').off('click');
	$('body.readybuilt .zoom-in-modal .ico-zoom-out').click(function(e){
		e.preventDefault();
		panZoom2.zoomOut();
	});

	if ($('html').hasClass('edge')) {
		$('body.readybuilt .zoom-in-modal .center .right.block').css('visibility', 'visible');
	}
	else {
		if ($('body.readybuilt .zoom-in-modal').css('visibility') == 'visible') $('body.readybuilt .zoom-in-modal .center .right.block').css('visibility', 'visible');
	}
}
if ($('body').hasClass('readybuilt')) {
	rbZoomTimeout = setTimeout(function() {
		if ($("#zoom_svg1").length > 0) {
			rb_zoom_svgPanZoom('zoom_svg1');
		}
	}, 3000);
}

function window_width()
{
	  var myWidth = 0;
	  if( typeof( window.innerWidth ) == 'number' )
		myWidth = window.innerWidth;
	  else if ( (document.documentElement) && (document.documentElement.clientWidth) )
		myWidth = document.documentElement.clientWidth;
	  else if( (document.body) && (document.body.clientWidth) )
		myWidth = document.body.clientWidth;
	  return(myWidth);
}
////////////////////////////////////////////////
function window_height()
{
	 var myHeight = 0;
	  if( typeof( window.innerHeight ) == 'number' )
		myHeight = window.innerHeight;
	  else if ( (document.documentElement) && (document.documentElement.clientHeight) )
		myHeight = document.documentElement.clientHeight;
	  else if( document.body && (document.body.clientHeight) )
		myHeight = document.body.clientHeight;
	  return(myHeight);
}
function propertyItemSlider() {
	if ($('body').hasClass('new-homes')) {
		$('.property-item__slider').slick({
			autoplay: false,
			arrows: false,
			dots: false,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			swipeToSlide: true
		});
	}
	else {
		$('.property-item__slider').slick({
			autoplay: false,
			arrows: true,
			dots: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			swipeToSlide: true
		});
	}
	if ($('body').hasClass("readybuilt-landing")) {
		$('.property-item__slider').on('afterChange', function(slick, currentSlide) {
			var $currentSlide = $(this).find('.property-item__slide.slick-current'),
				dataBannerMessage = $currentSlide.attr('data-banner-message'),
				dataBannerMessageColour = $currentSlide.attr('data-banner-colour');

			if ($currentSlide.find('.property-item__sold').length == 0) {
				var $banner = $currentSlide.parents('.property-item__panel').children('.property-item_banner'),
					showEstateBannerMessage = true;
				$banner.hide().html('').removeClass('property-item_banner-black').removeClass('property-item_banner-grey');

				if (typeof dataBannerMessage !== typeof undefined && dataBannerMessage !== false) {
					if (dataBannerMessage.length > 0) {
						showEstateBannerMessage = false;
						$banner.html(dataBannerMessage).show();
						if (typeof dataBannerMessageColour !== typeof undefined && dataBannerMessageColour !== false) {
							if (dataBannerMessageColour == 'black' || dataBannerMessageColour == 'grey') {
								$banner.addClass('property-item_banner-' + dataBannerMessageColour)
							}
						}
					}
				}
				if (showEstateBannerMessage === true) {
					var estateBannerMessage = $banner.attr('data-estate-banner-message'),
						estateBannerMessageColour = $banner.attr('data-estate-banner-colour');
					if (typeof estateBannerMessage !== typeof undefined && estateBannerMessage !== false) {
						if (estateBannerMessage.length > 0) {
							$banner.html(estateBannerMessage).show();
							if (typeof estateBannerMessageColour !== typeof undefined && estateBannerMessageColour !== false) {
								if (estateBannerMessageColour == 'black' || estateBannerMessageColour == 'grey') {
									$banner.addClass('property-item_banner-' + estateBannerMessageColour)
								}
							}
						}
					}
				}
			}
		});
	}
	if ($('body').hasClass('new-homes')) {
		$('.property-item__dots').on('click', 'a', function () {
			var slideID       = $(this).attr('data-slide'),
				$gotoSlide    = $('#slide-' + slideID),
				slideNumber   = $gotoSlide.attr('data-slide-number'),
				$propertyDots = $(this).parents('ul.property-item__dots'),
				$slider       = $propertyDots.next('.property-item__slider');
			$slider.slick('slickGoTo', slideNumber);
			return false;
		});
		$('.property-item__slider').each(function() {
			var $thisSlider = $(this);
			$thisSlider.on('afterChange', function(slick, currentSlide) {
				var $thisSliderBody        = $thisSlider.parents('.property-item__body'),
					$thisSliderDots        = $thisSliderBody.children('.property-item__dots'),
					$currentSlide          = $thisSlider.find('.slick-current'),
					currentSlideID         = $currentSlide.attr('id').replace('slide-', ''),
					$currentSlideClickable = $currentSlide.children('.property-item__body-clickable'),
					$photoClickable        = $thisSliderBody.prev('.property-item__photo-clickable');
				// show an accurate representation of which floor plan is the currently displayed one
				$thisSliderDots.find('a.active').removeClass('active');
				$thisSliderDots.find('a#property-dot-' + currentSlideID).addClass('active');
				// change the photo clickable href in case the user clicks on the photo
				$photoClickable.attr('href', $currentSlideClickable.attr('href'));
			});
		});
	}
}

var pinchZoomEventsHandler;

    pinchZoomEventsHandler = {
      haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel']
    , init: function(options) {
        var instance = options.instance
          , initialScale = 1
          , pannedX = 0
          , pannedY = 0

        // Init Hammer
        // Listen only for pointer and touch events
        this.hammer = Hammer(options.svgElement, {
          inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
        })

        // Enable pinch
        this.hammer.get('pinch').set({enable: true})

        // Handle double tap
        this.hammer.on('doubletap', function(ev){
          instance.zoomIn()
        })

        // Handle pan
        this.hammer.on('panstart panmove', function(ev){
          // On pan start reset panned variables
          if (ev.type === 'panstart') {
            pannedX = 0
            pannedY = 0
          }

          // Pan only the difference
          instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY})
          pannedX = ev.deltaX
          pannedY = ev.deltaY
        })

        // Handle pinch
        this.hammer.on('pinchstart pinchmove', function(ev){
          // On pinch start remember initial zoom
          if (ev.type === 'pinchstart') {
            initialScale = instance.getZoom()
            instance.zoom(initialScale * ev.scale)
          }

          instance.zoom(initialScale * ev.scale)

        })

        // Prevent moving the page on some devices when panning over SVG
        options.svgElement.addEventListener('touchmove', function(e){ e.preventDefault(); });
      }

    , destroy: function(){
        this.hammer.destroy()
      }
    };
